import { quotationTplActions } from 'app/inspection/duck/actions';
import { QuotationTemplateConfigState } from 'app/inspection/duck/states';
import { useAclContext } from 'lib/decorators/acl';
import {
  AclObjectList,
  QuotationTemplateCategoryStaged,
  QuotationTemplateConfigStaged,
} from 'model';
import {
  FocusEvent,
  KeyboardEvent,
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
} from 'react';
import { useDispatch } from 'react-redux';

type ContextProps = {
  state: QuotationTemplateConfigState;
  staged: QuotationTemplateConfigStaged;
  editable: boolean;
  onAddCategory: () => void;
  onEditCategory: (category: QuotationTemplateCategoryStaged) => void;
  onCategoryNameKeyup: (e: KeyboardEvent) => void;
  onCategoryNameBlur: (e: FocusEvent) => void;
  onToggleCategory: (category: QuotationTemplateCategoryStaged) => void;
  onMoveCategory: (
    category: QuotationTemplateCategoryStaged,
    from: number,
    to: number,
  ) => void;
  onRemoveCategory: (category: QuotationTemplateCategoryStaged) => void;
  onConfirmRemoveCategory: () => void;
  onCancelRemoveCategory: () => void;
  onAddSubject: () => void;
};

export const QuotationTemplateConfigContext = createContext<ContextProps>(
  null as any,
);

export const useQuotationTemplateConfigContext = () =>
  useContext(QuotationTemplateConfigContext);

export const QuotationTemplateConfigProvider = memo(
  ({
    state,
    children,
  }: {
    state: QuotationTemplateConfigState;
    children?: ReactNode;
  }) => {
    const dispatch = useDispatch();
    const acl = useAclContext();
    const editable = acl.$has(AclObjectList.VehicleQuotationTemplateFullAccess);

    const onAddCategory = useCallback(() => {
      dispatch(quotationTplActions.addCategory());
    }, [dispatch]);

    const onEditCategory = useCallback(
      (category: QuotationTemplateCategoryStaged) => {
        if (category == null) return;
        dispatch(quotationTplActions.editCategory(category.id));
      },
      [dispatch],
    );

    const onCategoryNameKeyup = useCallback(
      (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          // cancel
          dispatch(quotationTplActions.editCategoryCancelled());
        } else if (e.key === 'Enter') {
          // enter
          const el = e.target as HTMLInputElement;
          const name = el.value.trim();
          if (name.length) {
            dispatch(quotationTplActions.editCategoryCommitted(name));
          }
        }
      },
      [dispatch],
    );

    const onCategoryNameBlur = useCallback(
      (e: FocusEvent) => {
        const el = e.target as HTMLInputElement;
        const name = el.value.trim();
        if (name.length) {
          dispatch(quotationTplActions.editCategoryCommitted(name));
        } else {
          dispatch(quotationTplActions.editCategoryCancelled());
        }
      },
      [dispatch],
    );

    const onToggleCategory = useCallback(
      (category: QuotationTemplateCategoryStaged) => {
        dispatch(
          category.expanded
            ? quotationTplActions.collapseCategory(category.id)
            : quotationTplActions.expandCategory(category.id),
        );
      },
      [dispatch],
    );

    const onMoveCategory = useCallback(
      (category: QuotationTemplateCategoryStaged, from: number, to: number) => {
        dispatch(quotationTplActions.categoryMoved(category.id, from, to));
      },
      [dispatch],
    );

    const onRemoveCategory = useCallback(
      (category: QuotationTemplateCategoryStaged) => {
        dispatch(quotationTplActions.removeCategory(category.id));
      },
      [dispatch],
    );

    const onConfirmRemoveCategory = useCallback(() => {
      dispatch(quotationTplActions.commitRemoveCategory());
    }, [dispatch]);

    const onCancelRemoveCategory = useCallback(() => {
      dispatch(quotationTplActions.cancelRemoveCategory());
    }, [dispatch]);

    const onAddSubject = useCallback(() => {
      if (state.selectedGroupId == null) return;
    }, [state.selectedGroupId]);

    return (
      <QuotationTemplateConfigContext.Provider
        value={{
          state,
          staged: state.staged,
          editable,
          onAddCategory,
          onEditCategory,
          onCategoryNameKeyup,
          onCategoryNameBlur,
          onToggleCategory,
          onMoveCategory,
          onRemoveCategory,
          onConfirmRemoveCategory,
          onCancelRemoveCategory,
          onAddSubject,
        }}
      >
        {children}
      </QuotationTemplateConfigContext.Provider>
    );
  },
);
