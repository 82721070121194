/**
 * @file: QuotationStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, QuotationStatus } from 'model';

export const QuotationStatusOptions: Array<Option<QuotationStatus>> = [
  { value: QuotationStatus.Pending, label: 'quotation_status.pending' },
  { value: QuotationStatus.Started, label: 'quotation_status.started' },
  { value: QuotationStatus.Initiated, label: 'quotation_status.initiated' },
  { value: QuotationStatus.Submitted, label: 'quotation_status.submitted' },
  { value: QuotationStatus.Confirmed, label: 'quotation_status.confirmed' },
  { value: QuotationStatus.Finished, label: 'quotation_status.finished' },
];

export const QuotationStatusOptionsWithDefault: Array<
  Option<QuotationStatus | null>
> = [
  { value: null, label: 'quotation_status.__default__' },
  { value: QuotationStatus.Pending, label: 'quotation_status.pending' },
  { value: QuotationStatus.Started, label: 'quotation_status.started' },
  { value: QuotationStatus.Initiated, label: 'quotation_status.initiated' },
  { value: QuotationStatus.Submitted, label: 'quotation_status.submitted' },
  { value: QuotationStatus.Confirmed, label: 'quotation_status.confirmed' },
  { value: QuotationStatus.Finished, label: 'quotation_status.finished' },
];
