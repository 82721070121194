import classNames from 'classnames';
import React, { Component, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { Block } from 'shared/metronic/components';

interface Props {
  width?: string | number | (() => string | number);
  open?: boolean;
  spin?: boolean;
  onConfirmClose?: () => boolean | undefined;
  onClose?: () => void;
  autoClose?: boolean;
  preventEscClose?: boolean;
  floatedContents?: ReactNode | null;
  scrollWrapperRef?: React.Ref<HTMLElement>;
  children?: ReactNode;
}

export class AsideRight extends Component<Props> {
  static defaultProps: Props = {
    width: 455,
    open: false,
    spin: false,
    onClose: undefined,
    autoClose: true,
    floatedContents: null,
  };

  private readonly elemRef = React.createRef<HTMLDivElement>();
  private readonly overlayRef = React.createRef<HTMLDivElement>();

  render() {
    return ReactDOM.createPortal(this.internalRender(), document.body);
  }

  componentDidMount() {
    if (this.props.open) {
      this.elemRef.current &&
        this.elemRef.current.classList.add('m-quick-sidebar--on');
      document.body.classList.add('m-quick-sidebar--on');
      document.body.style.overflow = 'hidden';
      document.addEventListener('keyup', this.onKeyUp);
      setTimeout(() => {
        this.props.autoClose !== false &&
          this.overlayRef.current &&
          this.overlayRef.current.addEventListener('click', this.onClose);
      }, 0);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onKeyUp);
    document.body.classList.remove('m-quick-sidebar--on');
    document.body.style.overflow = 'auto';
    this.elemRef.current &&
      this.elemRef.current.classList.remove('m-quick-sidebar--on');
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.open !== prevProps.open) {
      if (this.props.open) {
        setTimeout(() => {
          document.body.classList.add('m-quick-sidebar--on');
          document.body.style.overflow = 'hidden';
          this.elemRef.current &&
            this.elemRef.current.classList.add('m-quick-sidebar--on');
          document.addEventListener('keyup', this.onKeyUp);
          setTimeout(() => {
            this.props.autoClose !== false &&
              this.overlayRef.current &&
              this.overlayRef.current.addEventListener('click', this.onClose);
          }, 0);
        }, 0);
      } else {
        this.overlayRef.current &&
          this.overlayRef.current.removeEventListener('click', this.onClose);
        this.elemRef.current &&
          this.elemRef.current.classList.remove('m-quick-sidebar--on');
        document.body.style.overflow = 'auto';
        document.body.classList.remove('m-quick-sidebar--on');
        document.removeEventListener('keyup', this.onKeyUp);
      }
    }
  }

  onKeyUp = (e: KeyboardEvent) => {
    if (
      e.key === 'Escape' &&
      this.props.autoClose !== false &&
      this.props.preventEscClose !== true
    ) {
      this.onClose();
    }
  };

  internalRender() {
    const { open, spin, scrollWrapperRef } = this.props;
    let width = this.props.width;
    if (typeof width === 'function') {
      width = width();
    }
    return (
      <>
        <div
          ref={this.elemRef}
          className={classNames(
            'm-quick-sidebar m-quick-sidebar--tabbed m-quick-sidebar--skin-light',
          )}
          style={{
            overflow: 'visible',
            borderLeft: '1px solid #ebedf2',
            width,
            right:
              width && typeof width === 'number' ? -(width + 10) : undefined,
          }}
        >
          <Block
            active={spin}
            $zIndex={1005}
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <div
              className="m-quick-sidebar-scroll-wrapper"
              ref={scrollWrapperRef as any}
              style={{
                backgroundColor: '#fff',
                overflowY: 'auto',
                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                padding: '20px 30px 30px 30px',
                zIndex: 1001,
              }}
            >
              <div className="m-quick-sidebar__content">
                <span
                  className="m-quick-sidebar__close"
                  style={{ cursor: 'pointer' }}
                  onClick={this.onClose}
                >
                  <i className="la la-close" />
                </span>
                {this.props.children}
              </div>
            </div>
          </Block>
          {this.props.floatedContents && (
            <div
              style={{
                position: 'absolute',
                left: -456,
                top: 100,
                bottom: 100,
                width: 455,
                overflowY: 'auto',
                backgroundColor: '#fff',
                boxShadow: '0px 1px 15px 1px rgba(69,65,78,0.08)',
              }}
            >
              {this.props.floatedContents}
            </div>
          )}
        </div>
        {open && (
          <div
            className="m-quick-sidebar-overlay"
            style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}
            ref={this.overlayRef}
          />
        )}
      </>
    );
  }

  onClose = () => {
    if (!this.props.onConfirmClose || this.props.onConfirmClose() !== false) {
      this.props.onClose && this.props.onClose();
    }
  };
}
