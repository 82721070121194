/**
 * @file: VehicleInspectionTaskQuotationIssueService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleInspectionTaskQuotationIssue,
  VehicleInspectionTaskQuotationIssueListFilter,
} from 'model';

export class VehicleInspectionTaskQuotationIssueServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?:
      | Partial<VehicleInspectionTaskQuotationIssueListFilter>
      | null
      | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleInspectionTaskQuotationIssue>
      : VehicleInspectionTaskQuotationIssue[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-inspection-task-quotation-issues',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleInspectionTaskQuotationIssue | null> {
    return await this.api
      .get()
      .url('/vehicle-inspection-task-quotation-issues/:id', { id })
      .future();
  }

  async create(
    vehicleInspectionTaskQuotationIssue: Partial<VehicleInspectionTaskQuotationIssue>,
  ): Promise<VehicleInspectionTaskQuotationIssue> {
    return await this.api
      .post()
      .url('/vehicle-inspection-task-quotation-issues')
      .data(vehicleInspectionTaskQuotationIssue)
      .future();
  }

  async update(
    vehicleInspectionTaskQuotationIssue: Partial<VehicleInspectionTaskQuotationIssue>,
  ): Promise<VehicleInspectionTaskQuotationIssue> {
    return await this.api
      .put()
      .url('/vehicle-inspection-task-quotation-issues/:id', {
        id: vehicleInspectionTaskQuotationIssue.id,
      })
      .data(vehicleInspectionTaskQuotationIssue)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/vehicle-inspection-task-quotation-issues/:id', { id })
      .future();
  }
}
