import { quotationTplActions } from 'app/inspection/duck/actions';
import { useQuotationTemplateConfigContext } from 'app/inspection/quotation-template-config/Context';
import {
  QuotationTemplateCategoryStaged,
  QuotationTemplateGroupStaged,
} from 'model';
import { FC, ReactNode, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  SortEndHandler,
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc';
import { GroupListItem } from './GroupListItem';

const SortableGroupListItem = SortableElement(GroupListItem);

const Container: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="quotation-tpl__group-list-sortable">{children}</div>;
};

const SortableGroupList = SortableContainer(Container);

export const GroupList = memo(
  ({ category }: { category: QuotationTemplateCategoryStaged }) => {
    const dispatch = useDispatch();
    const { state, editable } = useQuotationTemplateConfigContext();

    const onGroupClick = useCallback(
      (group: QuotationTemplateGroupStaged | undefined) => {
        if (group == null) return;
        if (state.selectedGroupId === group.id) {
          dispatch(quotationTplActions.editGroup(group.id, group.name));
        } else {
          dispatch(quotationTplActions.groupSelected(group.id));
        }
      },
      [dispatch, state.selectedGroupId],
    );

    const onGroupSortEnd: SortEndHandler = useCallback(
      e => {
        const { newIndex, oldIndex } = e;
        const group = category.groups[oldIndex];
        if (newIndex === oldIndex) return;
        dispatch(quotationTplActions.groupMoved(group.id, oldIndex, newIndex));
      },
      [category.groups, dispatch],
    );

    const onRemoveGroup = useCallback(
      (group: QuotationTemplateGroupStaged) => {
        dispatch(quotationTplActions.removeGroup(group.id));
      },
      [dispatch],
    );

    const onEndEditGroupName = useCallback(
      (_group: QuotationTemplateGroupStaged, name: string) => {
        dispatch(quotationTplActions.editGroupCommitted(name));
      },
      [dispatch],
    );

    const onCancelEditGroupName = useCallback(() => {
      dispatch(quotationTplActions.editGroupCancelled());
    }, [dispatch]);

    return (
      <SortableGroupList
        helperClass="quotation-tpl__group--being-dragged"
        onSortEnd={onGroupSortEnd}
        lockAxis="y"
        useWindowAsScrollContainer={true}
        distance={1}
      >
        {category.groups.map((group, index) => (
          <SortableGroupListItem
            key={group.id}
            group={group}
            index={index}
            selected={group.id === state.selectedGroupId}
            itemCount={group.subjects.length}
            editMode={state.groupIdWhoseNameIsBeingEdited == group.id}
            editable={editable}
            onClick={onGroupClick}
            onRemove={onRemoveGroup}
            onEndEditGroupName={onEndEditGroupName}
            onCancelEditGroupName={onCancelEditGroupName}
          />
        ))}
      </SortableGroupList>
    );
  },
);
