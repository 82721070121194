import React, { PureComponent } from 'react';
import { RadioButton } from 'shared/components/RadioButton';
import { FormElementRadioList } from '../types';

interface Props<T> {
  element: FormElementRadioList<T>;
  disabled?: boolean;
  options: any[] | null | undefined;
  value: any;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
}

export class FormRadioList<T> extends PureComponent<Props<T>> {
  render() {
    const { element, value, options, disabled } = this.props;
    const valueProp = element.valueProp || 'value';
    const labelProp = element.labelProp || 'label';

    return (
      <div className={element.inline ? 'm-radio-inline' : 'm-radio-list'}>
        {(options || []).map(option => (
          <RadioButton
            label={option[labelProp]}
            key={option[valueProp]}
            value={option[valueProp]}
            checked={Boolean((option[valueProp] === value))}
            disabled={disabled}
            onChange={this.onChange}
            />
        ))}
      </div>
    );
  }

  onChange = (value: any) => {
    const { element, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const val = element.convertValue ? element.convertValue(value) : value;
    const changes: { [K in keyof T]?: T[K] } = {};
    changes[element.prop] = val;
    element.onChange && element.onChange(changes, extra);
    this.props.onChange(changes);
  }
}