import { makeId } from 'app/inspection/common/helpers';
import { QuotationTemplateConfigState } from 'app/inspection/duck/states';
import { quotationTemplateConfigToStaged } from 'app/inspection/quotation-template-config/util';
import { Draft, produce } from 'immer';
import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncActionReducers } from 'lib/duck/reducers';
import {
  QuotationTemplateCategoryStaged,
  QuotationTemplateConfig,
  QuotationTemplateGroupStaged,
} from 'model';
import { array_move } from 'utils';
import { makeUniqueIdAlphabetic } from 'utils/id';
import { ActionTypes } from '../types';

const initialState: QuotationTemplateConfigState = {
  isLoading: false,
  dirty: false,
  error: null,
  result: null,
  selectedGroupId: null,
  categoryIdBeingRemoved: null,
  groupIdBeingRemoved: null,
  categoryMap: new Map(),
  groupMap: new Map(),
  staged: {
    categories: [],
  },
};

const defaultReducer = createAsyncActionReducers<
  QuotationTemplateConfig,
  QuotationTemplateConfigState
>('inspection.quotation-template-config', initialState);

const stateInitializedWithTemplateConfig = (
  draft: Draft<QuotationTemplateConfigState>,
  quotationTemplateConfig: QuotationTemplateConfig,
) => {
  const staged = quotationTemplateConfigToStaged(quotationTemplateConfig);
  Object.assign(draft, {
    staged,
    dirty: false,
    selectedGroupId: null,
    allGroupsSelectedCategoryId: null,
    selectedSubjectId: null,
    categoryIdBeingRemoved: null,
    groupIdBeingRemoved: null,
    categoryIdWhoseNameIsBeingEdited: null,
    categoryNameBeingEdited: null,
    groupIdWhoseNameIsBeingEdited: null,
    categoryIdForNewGroup: null,
    groupNameBeingEdited: null,
    isSaving: false,
    saveError: null,
  });
  for (const category of staged.categories) {
    draft.categoryMap.set(category.id, category);
    for (const group of category.groups) {
      draft.groupMap.set(group.id, group);
    }
  }
};

export default function (
  state: QuotationTemplateConfigState,
  action: StandardAction<any>,
): QuotationTemplateConfigState {
  switch (action.type) {
    case ActionTypes.QuotationTemplateConfigChanged: {
      const { recipe, options } = action.payload;
      return stateUpdated(state, recipe, options);
    }
    case ActionTypes.ResetQuotationTemplateConfigChanges: {
      const quotationTemplateConfig: QuotationTemplateConfig = action.payload ??
        state.result ?? { version: '1.0', categories: [] };
      return produce(state, draft => {
        stateInitializedWithTemplateConfig(draft, quotationTemplateConfig);
      });
    }

    case ActionTypes.QuotationTemplateConfigLoaded: {
      const quotationTemplateConfig = action.payload as QuotationTemplateConfig;
      return produce(state, draft => {
        stateInitializedWithTemplateConfig(draft, quotationTemplateConfig);
      });
    }
    case ActionTypes.QuotationTemplateConfigSaveSuccess: {
      return produce(state, draft => {
        draft.dirty = false;
      });
    }

    ////////////////////////////////////////////////////////////////
    // begin category related action handlers
    ////////////////////////////////////////////////////////////////
    case ActionTypes.QuotationTemplateConfigAddCategory: {
      return { ...state, categoryNameBeingEdited: '' };
    }
    case ActionTypes.QuotationTemplateConfigEditCategory: {
      const categoryId = action.payload as string;
      const category = state.staged.categories.find(x => x.id === categoryId)!;
      return produce(state, draft => {
        draft.categoryIdWhoseNameIsBeingEdited = categoryId;
        draft.categoryNameBeingEdited = category.name;
      });
    }
    case ActionTypes.QuotationTemplateConfigEditCategoryChanged: {
      return {
        ...state,
        categoryNameBeingEdited: action.payload,
      };
    }
    case ActionTypes.QuotationTemplateConfigEditCategoryCommitted: {
      const categoryId = state.categoryIdWhoseNameIsBeingEdited;
      if (categoryId) {
        return stateWithCategoryUpdated(
          state,
          categoryId,
          category => {
            category.name = action.payload || state.categoryNameBeingEdited!;
          },
          x => {
            x.categoryIdWhoseNameIsBeingEdited = null;
            x.categoryNameBeingEdited = null;
          },
        );
      } else {
        return stateWithCategoriesUpdated(
          state,
          (categories, draft) => {
            const category: QuotationTemplateCategoryStaged = {
              id: makeId(),
              name: action.payload || state.categoryNameBeingEdited!,
              groups: [],
              expanded: true,
            };
            categories.push(category);
            draft.categoryMap.set(category.id, category);
          },
          x => {
            x.categoryIdWhoseNameIsBeingEdited = null;
            x.categoryNameBeingEdited = null;
          },
        );
      }
    }
    case ActionTypes.QuotationTemplateConfigEditCategoryCancelled: {
      return {
        ...state,
        categoryIdWhoseNameIsBeingEdited: null,
        categoryNameBeingEdited: null,
      };
    }
    case ActionTypes.QuotationTemplateConfigRemoveCategory: {
      return { ...state, categoryIdBeingRemoved: action.payload };
    }
    case ActionTypes.QuotationTemplateConfigCommitRemoveCategory: {
      if (!state.categoryIdBeingRemoved) return state;
      return stateWithCategoryUpdated(
        state,
        state.categoryIdBeingRemoved,
        (category, index, draft) => {
          draft.categoryMap.delete(category.id);
          draft.staged.categories.splice(index, 1);
        },
        x => {
          x.categoryIdBeingRemoved = null;
        },
      );
    }
    case ActionTypes.QuotationTemplateConfigCancelRemoveCategory: {
      if (!state.categoryIdBeingRemoved) return state;
      return { ...state, categoryIdBeingRemoved: null };
    }
    case ActionTypes.QuotationTemplateConfigExpandCategory: {
      const id = action.payload as string;
      return stateWithCategoryUpdated(
        state,
        id,
        category => {
          category.expanded = true;
        },
        undefined,
        { markAsDirty: false },
      );
    }
    case ActionTypes.QuotationTemplateConfigCollapseCategory: {
      const id = action.payload as string;
      return stateWithCategoryUpdated(
        state,
        id,
        category => {
          category.expanded = false;
        },
        undefined,
        { markAsDirty: false },
      );
    }
    case ActionTypes.QuotationTemplateConfigCategoryMoved: {
      const { from, to } = action.payload as {
        id: string;
        from: number;
        to: number;
      };
      return stateWithCategoriesUpdated(state, categories => {
        array_move(categories, from, to);
      });
    }

    ////////////////////////////////////////////////////////////////
    // begin group related action handlers
    ////////////////////////////////////////////////////////////////
    case ActionTypes.QuotationTemplateConfigGroupSelected: {
      return {
        ...state,
        selectedGroupId: action.payload,
        allGroupsSelectedCategoryId: null,
      };
    }
    case ActionTypes.QuotationTemplateConfigAllGroupsSelected: {
      return {
        ...state,
        selectedGroupId: null,
        allGroupsSelectedCategoryId: action.payload,
      };
    }
    case ActionTypes.QuotationTemplateConfigAddGroup: {
      const categoryId = action.payload as string;
      return {
        ...state,
        categoryIdForNewGroup: categoryId,
        groupNameBeingEdited: '',
        groupIdWhoseNameIsBeingEdited: null,
      };
    }
    case ActionTypes.QuotationTemplateConfigEditGroup: {
      const { id, name } = action.payload;
      return {
        ...state,
        categoryIdForNewGroup: null,
        groupIdWhoseNameIsBeingEdited: id,
        groupNameBeingEdited: name,
      };
    }
    case ActionTypes.QuotationTemplateConfigEditGroupChanged: {
      return {
        ...state,
        groupNameBeingEdited: action.payload,
      };
    }
    case ActionTypes.QuotationTemplateConfigEditGroupCommitted: {
      const groupId = state.groupIdWhoseNameIsBeingEdited;
      if (!groupId) {
        const categoryId = state.categoryIdForNewGroup!;
        const id = makeUniqueIdAlphabetic();
        const name = action.payload || state.groupNameBeingEdited!;
        return stateWithCategoryUpdated(
          state,
          categoryId,
          (category, _, draft) => {
            const group: QuotationTemplateGroupStaged = {
              id,
              categoryId,
              name,
              subjects: [],
            };
            category.groups = [...category.groups, group];
            draft.groupMap.set(id, group);
          },
          x => {
            x.selectedGroupId = id;
            x.groupIdWhoseNameIsBeingEdited = null;
            x.groupNameBeingEdited = null;
            x.categoryIdForNewGroup = null;
            x.allGroupsSelectedCategoryId = null;
          },
        );
      } else {
        return stateWithGroupUpdated(
          state,
          groupId,
          group => {
            group.name = action.payload || state.groupNameBeingEdited!;
          },
          x => {
            x.groupIdWhoseNameIsBeingEdited = null;
            x.groupNameBeingEdited = null;
            x.categoryIdForNewGroup = null;
          },
        );
      }
    }
    case ActionTypes.QuotationTemplateConfigEditGroupCancelled: {
      return {
        ...state,
        groupIdWhoseNameIsBeingEdited: null,
        categoryIdForNewGroup: null,
        groupNameBeingEdited: null,
      };
    }
    case ActionTypes.QuotationTemplateConfigRemoveGroup: {
      return {
        ...state,
        groupIdBeingRemoved: action.payload,
      };
    }
    case ActionTypes.QuotationTemplateConfigCommitRemoveGroup: {
      const id = state.groupIdBeingRemoved!;
      return stateWithGroupUpdated(
        state,
        id,
        (group, index, category, draft) => {
          draft.groupMap.delete(group.id);
          category.groups.splice(index, 1);
        },
        x => {
          x.groupIdBeingRemoved = null;
        },
      );
    }
    case ActionTypes.QuotationTemplateConfigCancelRemoveGroup: {
      return {
        ...state,
        groupIdBeingRemoved: null,
      };
    }
    case ActionTypes.QuotationTemplateConfigGroupMoved: {
      const { id, from, to } = action.payload as {
        id: string;
        from: number;
        to: number;
      };
      return stateWithGroupUpdated(state, id, (_group, _index, category) => {
        array_move(category.groups, from, to);
      });
    }

    default: {
      return defaultReducer(state, action);
    }
  }
}

type StateUpdateOptions = {
  markAsDirty?: boolean;
};

function stateUpdated(
  state: QuotationTemplateConfigState,
  recipe: (draft: Draft<QuotationTemplateConfigState>) => void,
  options?: StateUpdateOptions,
) {
  const res = produce(state, recipe);
  if (res !== state && options?.markAsDirty !== false) {
    return produce(res, draft => {
      draft.dirty = true;
    });
  }
  return res;
}

function stateWithCategoriesUpdated(
  state: QuotationTemplateConfigState,
  update: (
    categories: Draft<QuotationTemplateCategoryStaged>[],
    state: Draft<QuotationTemplateConfigState>,
  ) => void,
  stateRecipe?: (state: QuotationTemplateConfigState) => void,
  options?: StateUpdateOptions,
): QuotationTemplateConfigState {
  return stateUpdated(
    state,
    draft => {
      update(draft.staged.categories, draft);
      stateRecipe?.(draft);
    },
    options,
  );
}

function stateWithCategoryUpdated(
  state: QuotationTemplateConfigState,
  categoryId: string,
  update: (
    category: Draft<QuotationTemplateCategoryStaged>,
    index: number,
    state: Draft<QuotationTemplateConfigState>,
  ) => void,
  stateRecipe?: (state: QuotationTemplateConfigState) => void,
  options?: StateUpdateOptions,
) {
  return stateWithCategoriesUpdated(
    state,
    (categories, draft) => {
      const index = categories.findIndex(x => x.id === categoryId);
      if (index < 0) {
        return;
      }
      update(categories[index], index, draft);
    },
    stateRecipe,
    options,
  );
}

function stateWithGroupUpdated(
  state: QuotationTemplateConfigState,
  groupId: string,
  update: (
    group: Draft<QuotationTemplateGroupStaged>,
    index: number,
    category: Draft<QuotationTemplateCategoryStaged>,
    state: Draft<QuotationTemplateConfigState>,
  ) => void,
  stateRecipe?: (state: Draft<QuotationTemplateConfigState>) => void,
  options?: StateUpdateOptions,
): QuotationTemplateConfigState {
  return stateUpdated(
    state,
    draft => {
      const group = draft.groupMap.get(groupId);
      if (group != null) {
        const category = draft.categoryMap.get(group.categoryId);
        if (category != null) {
          const index = category.groups.findIndex(x => x.id === groupId);
          update(group, index, category, draft);
        }
      }
      stateRecipe?.(draft);
    },
    options,
  );
}

// function mapUpdated<T = any, U = any>(
//   map: Map<T, U> | undefined | null,
//   key: T,
//   value: U,
// ): Map<T, U> {
//   const newMap = map ? new Map<T, U>([...map]) : new Map<T, U>();
//   newMap.set(key, value);
//   return newMap;
// }

// function mapKeyRemoved<T = any, U = any>(
//   map: Map<T, U> | undefined | null,
//   key: T,
// ): Map<T, U> {
//   const newMap = map ? new Map<T, U>([...map]) : new Map<T, U>();
//   newMap.delete(key);
//   return newMap;
// }
