import { VehicleInspectionTask } from 'model';
import { Component } from 'react';
import {
  InspectionOrderPriorityLabel,
  InspectionOrderTypeLabel,
  InspectionTaskStatusLabel,
  OrgInfoView,
  ScopedTranslate,
  StringLabel,
} from 'shared/components';
import { formatTime } from 'utils';

interface Props {
  task: VehicleInspectionTask | null | undefined;
}

export class TaskDetailView extends Component<Props> {
  render() {
    const { task } = this.props;
    if (!task) return null;
    return (
      <ScopedTranslate scope="tasks.detail.label">
        {({ trans }) => (
          <div className="task-detail">
            <h3>{trans('!tasks.detail.title')}</h3>
            <dl>
              <dt>{trans('org_name')}</dt>
              <dd>
                <OrgInfoView orgId={task.orgId} />
              </dd>
            </dl>
            <dl>
              <dt>{trans('store_name')}</dt>
              <dd>{task.storeName}</dd>
            </dl>
            <dl>
              <dt>{trans('task_no')}</dt>
              <dd>{task.taskNo}</dd>
            </dl>
            <dl>
              <dt>{trans('order_no')}</dt>
              <dd>{task.orderNo}</dd>
            </dl>
            <dl>
              <dt>{trans('order_type')}</dt>
              <dd>
                <InspectionOrderTypeLabel value={task.orderType} />
              </dd>
            </dl>
            <dl>
              <dt>{trans('vin')}</dt>
              <dd>
                {task.vin}
                {task.vinImgUrl && (
                  <p>
                    <a href={task.vinImgUrl} target="_blank" rel="noreferrer">
                      <img
                        src={task.vinImgUrl}
                        alt={task.vin}
                        style={{ width: 100 }}
                      />
                    </a>
                  </p>
                )}
              </dd>
            </dl>
            <dl>
              <dt>{trans('license_plate_no')}</dt>
              <dd>
                {task.licensePlateNo}
                {task.licensePlateNoImgUrl && (
                  <p>
                    <a
                      href={task.licensePlateNoImgUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={task.licensePlateNoImgUrl}
                        alt={task.licensePlateNo}
                        style={{ width: 100 }}
                      />
                    </a>
                  </p>
                )}
              </dd>
            </dl>
            <dl>
              <dt>{trans('vehicle_name')}</dt>
              <dd>{task.vehicleName}</dd>
            </dl>
            <dl>
              <dt>{trans('vehicle_brand')}</dt>
              <dd>{task.vehicleBrandName}</dd>
            </dl>
            <dl>
              <dt>{trans('vehicle_model')}</dt>
              <dd>{task.vehicleName}</dd>
            </dl>
            <dl>
              <dt>{trans('vehicle_mileage')}</dt>
              {task.vehicleMileage! < 1
                ? task.vehicleMileage! * 10000
                : task.vehicleMileage}{' '}
              <StringLabel
                value={
                  task.vehicleMileage! < 1
                    ? 'value_unit.km'
                    : 'value_unit.ten_thousands_km'
                }
              />
            </dl>
            <dl>
              <dt>{trans('sa_name')}</dt>
              <dd>{task.serviceAgentName}</dd>
            </dl>
            <dl>
              <dt>{trans('status')}</dt>
              <dd>
                <InspectionTaskStatusLabel value={task.status} />
              </dd>
            </dl>
            <dl>
              <dt>{trans('priority')}</dt>
              <dd>
                <InspectionOrderPriorityLabel value={task.priority} />
              </dd>
            </dl>
            <dl>
              <dt>{trans('created_at')}</dt>
              <dd>{formatTime(task.createdAt)}</dd>
            </dl>
            {task.startedAt && (
              <dl>
                <dt>{trans('started_at')}</dt>
                <dd>{formatTime(task.startedAt)}</dd>
              </dl>
            )}
            {task.suspendedAt && (
              <dl>
                <dt>{trans('suspended_at')}</dt>
                <dd>{formatTime(task.suspendedAt)}</dd>
              </dl>
            )}
            {task.finishedAt && (
              <dl>
                <dt>{trans('finished_at')}</dt>
                <dd>{formatTime(task.finishedAt)}</dd>
              </dl>
            )}
            <dl>
              <dt>{trans('remark')}</dt>
              <dd>{task.remark}</dd>
            </dl>
            {task.elapsedTime && (
              <dl>
                <dt>{trans('elapsed_time')}</dt>
                <dd>
                  {task.elapsedTime}
                  {trans('elapsed_time_unit')}
                </dd>
              </dl>
            )}
            <dl>
              <dt>{trans('total_site_count')}</dt>
              <dd>{task.totalSiteCount}</dd>
            </dl>
            <dl>
              <dt>{trans('abnormal_site_count')}</dt>
              <dd>{task.abnormalSiteCount}</dd>
            </dl>
            <dl>
              <dt>{trans('normal_site_count')}</dt>
              <dd>{task.normalSiteCount}</dd>
            </dl>
            <dl>
              <dt>{trans('urgent_site_count')}</dt>
              <dd>{task.urgentSiteCount}</dd>
            </dl>
          </div>
        )}
      </ScopedTranslate>
    );
  }
}
