import { quotationTplActions } from 'app/inspection/duck/actions';
import { useQuotationTemplateConfigContext } from 'app/inspection/quotation-template-config/Context';
import classNames from 'classnames';
import { QuotationTemplateCategoryStaged } from 'model';
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { GroupList } from '../group/GroupList';
import { GroupListItem } from '../group/GroupListItem';
import { NewGroup } from '../group/NewGroup';
import { WiredCategoryHeader } from './CategoryHeader';

export const CategoryView = memo(
  (props: {
    category: QuotationTemplateCategoryStaged;
    index: number;
    canMoveUp: boolean;
    canMoveDown: boolean;
  }) => {
    const { category } = props;
    const subjectCount = category.groups.flatMap(x => x.subjects).length;
    const dispatch = useDispatch();
    const { state, editable } = useQuotationTemplateConfigContext();

    const isAddingNewGroup = Boolean(
      state.groupNameBeingEdited != null &&
        state.groupIdWhoseNameIsBeingEdited == null &&
        state.categoryIdForNewGroup === category.id,
    );

    const onAllGroupsClick = useCallback(() => {
      dispatch(quotationTplActions.allGroupsSelected(category.id));
    }, [category.id, dispatch]);

    const onAddNewGroup = useCallback(() => {
      dispatch(quotationTplActions.addGroup(category.id));
    }, [category.id, dispatch]);

    const onEndEditNewGroupName = useCallback(
      (name: string) => {
        dispatch(quotationTplActions.editGroupCommitted(name));
      },
      [dispatch],
    );

    const onCancelEditNewGroupName = useCallback(() => {
      dispatch(quotationTplActions.editGroupCancelled());
    }, [dispatch]);

    return (
      <div
        key={category.id}
        className={classNames('quotation-tpl__category', {
          'quotation-tpl__category--expanded': category.expanded,
        })}
      >
        <WiredCategoryHeader {...props} />
        <div className="quotation-tpl__group-list">
          <GroupListItem
            itemCount={subjectCount}
            onClick={onAllGroupsClick}
            editable={false}
            selected={state.allGroupsSelectedCategoryId === category.id}
          />
          <GroupList category={category} />
          {editable && (
            <NewGroup
              editMode={isAddingNewGroup}
              onAdd={onAddNewGroup}
              onEndEdit={onEndEditNewGroupName}
              onCancelEdit={onCancelEditNewGroupName}
            />
          )}
        </div>
      </div>
    );
  },
);
