import React, { PureComponent } from 'react';
import { CheckButton } from 'shared/components/CheckButton';
import { getString } from 'shared/components/StringLabel';
import { FormElementCheckbox } from '../types';

interface Props<T> {
  element: FormElementCheckbox<T>;
  value: boolean;
  disabled?: boolean;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
}

export class FormCheckbox<T> extends PureComponent<Props<T>> {
  render() {
    const { element, value, disabled } = this.props;
    const checked = element.inverse ? !value : value;
    const label = typeof element.label === 'string' ?
      getString(element.label) : element.label;

    return (
      <CheckButton
        label={label}
        checked={checked}
        disabled={disabled}
        onChange={this.onChange}
        />
    );
  }

  onChange = (_: any, checked: boolean) => {
    const { element, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const changes: { [K in keyof T]?: T[K] } = {};
    changes[element.prop] = (element.inverse ? !checked : checked) as any;
    element.onChange && element.onChange(changes, extra);
    this.props.onChange(changes);
  }
}