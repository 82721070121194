/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { api, cacheService } from 'lib';

import { AclService } from './AclService';
import { AuthService } from './AuthService';
import { CommonService } from './CommonService';
import { LbsService } from './LbsService';
import { MediaService } from './MediaService';
import { OpenApiService } from './OpenApiService';
import { ReportService } from './ReportService';
import { StatsService } from './StatsService';

/* service_import_begin */
import { AdminUserAclService } from './AdminUserAclService';
import { AdminUserService } from './AdminUserService';
import { AgentService } from './AgentService';
import { AreaService } from './AreaService';
import { AutoPartNameNgramService } from './AutoPartNameNgramService';
import { AutoPartNameService } from './AutoPartNameService';
import { ConstructionTemplateConfigService } from './ConstructionTemplateConfigService';
import { CustomerService } from './CustomerService';
import { DataExportTaskService } from './DataExportTaskService';
import { InspectionToolService } from './InspectionToolService';
import { MaintenanceServiceSubjectService } from './MaintenanceServiceSubjectService';
import { OpenApiAppAuthorizedStoreService } from './OpenApiAppAuthorizedStoreService';
import { OpenApiAppService } from './OpenApiAppService';
import { OpenApiUserService } from './OpenApiUserService';
import { OrganizationService } from './OrganizationService';
import { OrgGroupService } from './OrgGroupService';
import { OrgMemberService } from './OrgMemberService';
import { OrgSubscribedUserService } from './OrgSubscribedUserService';
import { OrgTeamService } from './OrgTeamService';
import { OrgUserAclService } from './OrgUserAclService';
import { OrgUserAppleSignInBindingService } from './OrgUserAppleSignInBindingService';
import { OrgUserService } from './OrgUserService';
import { OrgUserVisibleStoreService } from './OrgUserVisibleStoreService';
import { OrgUserWeixinBindingService } from './OrgUserWeixinBindingService';
import { OrgWeixinTemplateConfService } from './OrgWeixinTemplateConfService';
import { ProductAgentService } from './ProductAgentService';
import { ProductAgentUserService } from './ProductAgentUserService';
import { ProductBrandService } from './ProductBrandService';
import { ProductService } from './ProductService';
import { QuotationPartCatalogService } from './QuotationPartCatalogService';
import { QuotationTemplateConfigService } from './QuotationTemplateConfigService';
import { ServiceEditionService } from './ServiceEditionService';
import { StoreCustomerService } from './StoreCustomerService';
import { StoreGatewayDeviceService } from './StoreGatewayDeviceService';
import { StoreMediaService } from './StoreMediaService';
import { StoreObdDeviceService } from './StoreObdDeviceService';
import { StoreService } from './StoreService';
import { StoreServiceEnrollmentService } from './StoreServiceEnrollmentService';
import { SysBatchJobService } from './SysBatchJobService';
import { SysErrorLogService } from './SysErrorLogService';
import { SysTaskSchedulerExecLogService } from './SysTaskSchedulerExecLogService';
import { SysTaskSchedulerInfoService } from './SysTaskSchedulerInfoService';
import { SystemService } from './SystemService';
import { UserService } from './UserService';
import { UserVehicleBindingCertificateService } from './UserVehicleBindingCertificateService';
import { UserVehicleBindingService } from './UserVehicleBindingService';
import { UserWeixinBindingService } from './UserWeixinBindingService';
import { VehicleDeliveryCheckTemplateBarrierService } from './VehicleDeliveryCheckTemplateBarrierService';
import { VehicleDeliveryCheckTemplateService } from './VehicleDeliveryCheckTemplateService';
import { VehicleInfoService } from './VehicleInfoService';
import { VehicleInspectionCommentService } from './VehicleInspectionCommentService';
import { VehicleInspectionSiteCategoryService } from './VehicleInspectionSiteCategoryService';
import { VehicleInspectionSiteCheckItemMediaService } from './VehicleInspectionSiteCheckItemMediaService';
import { VehicleInspectionSiteCheckItemOptionService } from './VehicleInspectionSiteCheckItemOptionService';
import { VehicleInspectionSiteCheckItemService } from './VehicleInspectionSiteCheckItemService';
import { VehicleInspectionSiteMaintenancePeriodService } from './VehicleInspectionSiteMaintenancePeriodService';
import { VehicleInspectionSiteMaintenancePeriodVarianceService } from './VehicleInspectionSiteMaintenancePeriodVarianceService';
import { VehicleInspectionSiteRelService } from './VehicleInspectionSiteRelService';
import { VehicleInspectionSiteService } from './VehicleInspectionSiteService';
import { VehicleInspectionSiteVersionService } from './VehicleInspectionSiteVersionService';
import { VehicleInspectionSubjectService } from './VehicleInspectionSubjectService';
import { VehicleInspectionTaskAssignmentService } from './VehicleInspectionTaskAssignmentService';
import { VehicleInspectionTaskCheckSiteItemDataService } from './VehicleInspectionTaskCheckSiteItemDataService';
import { VehicleInspectionTaskCheckSiteItemMediaService } from './VehicleInspectionTaskCheckSiteItemMediaService';
import { VehicleInspectionTaskCheckSiteItemService } from './VehicleInspectionTaskCheckSiteItemService';
import { VehicleInspectionTaskCheckSiteService } from './VehicleInspectionTaskCheckSiteService';
import { VehicleInspectionTaskCustomIssueMediaService } from './VehicleInspectionTaskCustomIssueMediaService';
import { VehicleInspectionTaskCustomIssueService } from './VehicleInspectionTaskCustomIssueService';
import { VehicleInspectionTaskDeliveryCheckMediaService } from './VehicleInspectionTaskDeliveryCheckMediaService';
import { VehicleInspectionTaskDeliveryCheckService } from './VehicleInspectionTaskDeliveryCheckService';
import { VehicleInspectionTaskDiagnosticJobMediaService } from './VehicleInspectionTaskDiagnosticJobMediaService';
import { VehicleInspectionTaskDiagnosticJobService } from './VehicleInspectionTaskDiagnosticJobService';
import { VehicleInspectionTaskDiagnosticJobTroubleCodeService } from './VehicleInspectionTaskDiagnosticJobTroubleCodeService';
import { VehicleInspectionTaskEventService } from './VehicleInspectionTaskEventService';
import { VehicleInspectionTaskFlowService } from './VehicleInspectionTaskFlowService';
import { VehicleInspectionTaskIssueRefService } from './VehicleInspectionTaskIssueRefService';
import { VehicleInspectionTaskJobDetailService } from './VehicleInspectionTaskJobDetailService';
import { VehicleInspectionTaskJobMediaService } from './VehicleInspectionTaskJobMediaService';
import { VehicleInspectionTaskJobService } from './VehicleInspectionTaskJobService';
import { VehicleInspectionTaskQuotationDetailService } from './VehicleInspectionTaskQuotationDetailService';
import { VehicleInspectionTaskQuotationFormService } from './VehicleInspectionTaskQuotationFormService';
import { VehicleInspectionTaskQuotationIssueService } from './VehicleInspectionTaskQuotationIssueService';
import { VehicleInspectionTaskQuotationService } from './VehicleInspectionTaskQuotationService';
import { VehicleInspectionTaskService } from './VehicleInspectionTaskService';
import { VehicleInspectionTaskSubjectService } from './VehicleInspectionTaskSubjectService';
import { VehicleInspectionTaskTemplateSnapshotService } from './VehicleInspectionTaskTemplateSnapshotService';
import { VehicleInspectionTaskTroubleCodeService } from './VehicleInspectionTaskTroubleCodeService';
import { VehicleInspectionTemplateBarrierService } from './VehicleInspectionTemplateBarrierService';
import { VehicleInspectionTemplateService } from './VehicleInspectionTemplateService';
import { VehicleIssueService } from './VehicleIssueService';
import { VehicleMaintenanceServicePlanDetailService } from './VehicleMaintenanceServicePlanDetailService';
import { VehicleMaintenanceServicePlanService } from './VehicleMaintenanceServicePlanService';
import { VehicleModelService } from './VehicleModelService';
import { VehicleReceptionOrderService } from './VehicleReceptionOrderService';
import { VehicleReceptionOrderTechnicianService } from './VehicleReceptionOrderTechnicianService';
import { VehicleReportOpenLogService } from './VehicleReportOpenLogService';
import { VehicleService } from './VehicleService';
import { VehicleServiceSubjectService } from './VehicleServiceSubjectService';
import { VehicleSubscriptionDetailService } from './VehicleSubscriptionDetailService';
import { VehicleSubscriptionService } from './VehicleSubscriptionService';
import { VehicleSystemModuleService } from './VehicleSystemModuleService';
/* service_import_end */

export const commonService = new CommonService(api, cacheService);
export const authService = new AuthService(api);
export const statsService = new StatsService(api);
export const aclService = new AclService(api);
export const systemService = new SystemService(api);
export const lbsService = new LbsService(api);
export const mediaService = new MediaService(api);
export const reportService = new ReportService(api);
export const openApiService = new OpenApiService(api);

/* instance_export_begin */
export const adminUserService = new AdminUserService(api);
export const adminUserAclService = new AdminUserAclService(api);
export const areaService = new AreaService(api);
export const orgMemberService = new OrgMemberService(api);
export const orgUserService = new OrgUserService(api);
export const orgUserAclService = new OrgUserAclService(api);
export const organizationService = new OrganizationService(api);
export const storeService = new StoreService(api);
export const sysErrorLogService = new SysErrorLogService(api);
export const userService = new UserService(api);
export const vehicleInfoService = new VehicleInfoService(api);
export const vehicleInspectionCommentService =
  new VehicleInspectionCommentService(api);
export const vehicleInspectionSiteCategoryService =
  new VehicleInspectionSiteCategoryService(api);
export const vehicleInspectionSiteCheckItemService =
  new VehicleInspectionSiteCheckItemService(api);
export const vehicleInspectionSiteCheckItemMediaService =
  new VehicleInspectionSiteCheckItemMediaService(api);
export const vehicleInspectionSiteCheckItemOptionService =
  new VehicleInspectionSiteCheckItemOptionService(api);
export const vehicleInspectionSiteMaintenancePeriodService =
  new VehicleInspectionSiteMaintenancePeriodService(api);
export const vehicleInspectionSiteMaintenancePeriodVarianceService =
  new VehicleInspectionSiteMaintenancePeriodVarianceService(api);
export const vehicleInspectionTaskCheckSiteService =
  new VehicleInspectionTaskCheckSiteService(api);
export const vehicleInspectionTaskCheckSiteItemService =
  new VehicleInspectionTaskCheckSiteItemService(api);
export const vehicleInspectionTaskCheckSiteItemDataService =
  new VehicleInspectionTaskCheckSiteItemDataService(api);
export const vehicleInspectionTaskCheckSiteItemMediaService =
  new VehicleInspectionTaskCheckSiteItemMediaService(api);
export const vehicleReceptionOrderTechnicianService =
  new VehicleReceptionOrderTechnicianService(api);
export const customerService = new CustomerService(api);
export const storeCustomerService = new StoreCustomerService(api);
export const inspectionToolService = new InspectionToolService(api);
export const vehicleInspectionTemplateService =
  new VehicleInspectionTemplateService(api);
export const orgUserWeixinBindingService = new OrgUserWeixinBindingService(api);
export const userWeixinBindingService = new UserWeixinBindingService(api);
export const vehicleInspectionTaskAssignmentService =
  new VehicleInspectionTaskAssignmentService(api);
export const orgUserVisibleStoreService = new OrgUserVisibleStoreService(api);
export const sysTaskSchedulerExecLogService =
  new SysTaskSchedulerExecLogService(api);
export const sysTaskSchedulerInfoService = new SysTaskSchedulerInfoService(api);
export const vehicleIssueService = new VehicleIssueService(api);
export const vehicleInspectionSiteRelService =
  new VehicleInspectionSiteRelService(api);
export const orgGroupService = new OrgGroupService(api);
export const orgTeamService = new OrgTeamService(api);
export const vehicleInspectionTaskEventService =
  new VehicleInspectionTaskEventService(api);
export const vehicleInspectionSubjectService =
  new VehicleInspectionSubjectService(api);
export const vehicleInspectionTaskJobService =
  new VehicleInspectionTaskJobService(api);
export const vehicleInspectionTaskTroubleCodeService =
  new VehicleInspectionTaskTroubleCodeService(api);
export const vehicleInspectionTaskDiagnosticJobService =
  new VehicleInspectionTaskDiagnosticJobService(api);
export const vehicleInspectionTaskDiagnosticJobMediaService =
  new VehicleInspectionTaskDiagnosticJobMediaService(api);
export const vehicleInspectionTaskDiagnosticJobTroubleCodeService =
  new VehicleInspectionTaskDiagnosticJobTroubleCodeService(api);
export const vehicleInspectionTaskJobDetailService =
  new VehicleInspectionTaskJobDetailService(api);
export const vehicleInspectionTaskJobMediaService =
  new VehicleInspectionTaskJobMediaService(api);
export const vehicleInspectionTaskSubjectService =
  new VehicleInspectionTaskSubjectService(api);
export const vehicleInspectionTaskCustomIssueService =
  new VehicleInspectionTaskCustomIssueService(api);
export const storeMediaService = new StoreMediaService(api);
export const vehicleInspectionTaskQuotationService =
  new VehicleInspectionTaskQuotationService(api);
export const vehicleInspectionTaskQuotationDetailService =
  new VehicleInspectionTaskQuotationDetailService(api);
export const quotationPartCatalogService = new QuotationPartCatalogService(api);
export const autoPartNameService = new AutoPartNameService(api);
export const vehicleInspectionTaskQuotationIssueService =
  new VehicleInspectionTaskQuotationIssueService(api);
export const vehicleInspectionTaskDeliveryCheckService =
  new VehicleInspectionTaskDeliveryCheckService(api);
export const vehicleInspectionTaskDeliveryCheckMediaService =
  new VehicleInspectionTaskDeliveryCheckMediaService(api);
export const vehicleInspectionTaskQuotationFormService =
  new VehicleInspectionTaskQuotationFormService(api);
export const vehicleDeliveryCheckTemplateService =
  new VehicleDeliveryCheckTemplateService(api);
export const orgSubscribedUserService = new OrgSubscribedUserService(api);
export const openApiAppService = new OpenApiAppService(api);
export const openApiUserService = new OpenApiUserService(api);
export const openApiAppAuthorizedStoreService =
  new OpenApiAppAuthorizedStoreService(api);
export const autoPartNameNgramService = new AutoPartNameNgramService(api);
export const storeGatewayDeviceService = new StoreGatewayDeviceService(api);
export const userVehicleBindingService = new UserVehicleBindingService(api);
export const userVehicleBindingCertificateService =
  new UserVehicleBindingCertificateService(api);
export const vehicleService = new VehicleService(api);
export const vehicleInspectionSiteVersionService =
  new VehicleInspectionSiteVersionService(api);
export const vehicleInspectionTaskCustomIssueMediaService =
  new VehicleInspectionTaskCustomIssueMediaService(api);
export const vehicleInspectionTaskIssueRefService =
  new VehicleInspectionTaskIssueRefService(api);
export const agentService = new AgentService(api);
export const vehicleModelService = new VehicleModelService(api);
export const maintenanceServiceSubjectService =
  new MaintenanceServiceSubjectService(api);
export const vehicleSystemModuleService = new VehicleSystemModuleService(api);
export const vehicleInspectionTemplateBarrierService =
  new VehicleInspectionTemplateBarrierService(api);
export const vehicleDeliveryCheckTemplateBarrierService =
  new VehicleDeliveryCheckTemplateBarrierService(api);
export const serviceEditionService = new ServiceEditionService(api);
export const vehicleMaintenanceServicePlanService =
  new VehicleMaintenanceServicePlanService(api);
export const orgUserAppleSignInBindingService =
  new OrgUserAppleSignInBindingService(api);
export const vehicleMaintenanceServicePlanDetailService =
  new VehicleMaintenanceServicePlanDetailService(api);
export const vehicleInspectionTaskTemplateSnapshotService =
  new VehicleInspectionTaskTemplateSnapshotService(api);
export const vehicleInspectionTaskFlowService =
  new VehicleInspectionTaskFlowService(api);
export const vehicleSubscriptionService = new VehicleSubscriptionService(api);
export const storeObdDeviceService = new StoreObdDeviceService(api);
export const vehicleSubscriptionDetailService =
  new VehicleSubscriptionDetailService(api);
export const vehicleReportOpenLogService = new VehicleReportOpenLogService(api);
export const vehicleServiceSubjectService = new VehicleServiceSubjectService(
  api,
);
export const storeServiceEnrollmentService = new StoreServiceEnrollmentService(
  api,
);
export const vehicleInspectionTaskService = new VehicleInspectionTaskService(
  api,
);
export const orgWeixinTemplateConfService = new OrgWeixinTemplateConfService(
  api,
);
export const vehicleReceptionOrderService = new VehicleReceptionOrderService(
  api,
);
export const vehicleInspectionSiteService = new VehicleInspectionSiteService(
  api,
);
export const dataExportTaskService = new DataExportTaskService(api);
export const sysBatchJobService = new SysBatchJobService(api);
export const constructionTemplateConfigService =
  new ConstructionTemplateConfigService(api);
export const quotationTemplateConfigService =
  new QuotationTemplateConfigService(api);
export const productAgentService = new ProductAgentService(api);
export const productAgentUserService = new ProductAgentUserService(api);
export const productService = new ProductService(api);
export const productBrandService = new ProductBrandService(api);
/* instance_import_end */
