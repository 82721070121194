import { ServiceEdition } from 'model';
import React, { PureComponent } from 'react';
import { ServiceEditionPicker } from 'shared/components/ServiceEditionPicker';
import {
  EntityWithServiceEditionProps,
  FormElementServiceEditionPicker,
} from '../types';

interface Props<T extends EntityWithServiceEditionProps> {
  element: FormElementServiceEditionPicker<T>;
  disabled?: boolean;
  editionId?: string | null;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
}

export class FormServiceEditionPicker<
  T extends EntityWithServiceEditionProps,
> extends PureComponent<Props<T>> {
  render() {
    const { editionId, disabled } = this.props;
    return (
      <ServiceEditionPicker
        serviceEditionId={editionId}
        disabled={disabled}
        onChange={this.onChange}
      />
    );
  }

  onChange = (edition: ServiceEdition | null) => {
    const { element, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const changes: { [K in keyof T]?: T[K] } = {};
    changes.editionId = edition?.sku;
    changes.editionName = edition?.name;
    element.onChange && element.onChange(changes as any, extra, edition);
    this.props.onChange(changes as T);
  };
}
