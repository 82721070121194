import React, { PureComponent } from 'react';
import { Switch } from 'shared/components/Switch';
import { FormElementSwitch } from '../types';

interface Props<T> {
  element: FormElementSwitch<T>;
  value: boolean;
  disabled?: boolean;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
}

export class FormSwitch<T> extends PureComponent<Props<T>> {
  render() {
    const { disabled, value } = this.props;
    return (
      <div>
        <Switch
          on={value}
          disabled={disabled}
          onChange={this.onChange}
          />
      </div>
    );
  }

  onChange = (checked: boolean) => {
    const { element, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const changes: { [K in keyof T]?: T[K] } = {};
    changes[element.prop] = checked as any;
    element.onChange && element.onChange(changes, extra);
    this.props.onChange(changes);
  }
}