import { AreaTree } from 'model';
import React, { PureComponent } from 'react';
import { AreaPicker } from 'shared/components/AreaPicker';
import { EntityWithAreaProps, FormElementAreaPicker } from '../types';

interface Props<T> {
  element: FormElementAreaPicker<T & EntityWithAreaProps>;
  disabled?: boolean;
  areas: AreaTree | undefined | null;
  provinceId?: number;
  cityId?: number;
  districtId?: number;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
}

export class FormAreaPicker<T> extends PureComponent<Props<T>> {
  render() {
    const { areas, provinceId, cityId, districtId, disabled } = this.props;
    return (
      <AreaPicker
        areas={areas}
        disabled={disabled}
        provinceId={provinceId}
        cityId={cityId}
        districtId={districtId}
        onChange={this.onChange}
      />
    );
  }

  onChange = (
    provinceId: number | undefined,
    cityId: number | undefined,
    districtId: number | undefined
  ) => {
    const { element, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const changes: { [K in keyof EntityWithAreaProps]?: EntityWithAreaProps[K] } = {};
    changes.provinceId = provinceId;
    changes.cityId = cityId;
    changes.districtId = districtId;
    element.onChange && element.onChange(changes as any, extra);
    this.props.onChange(changes as T);
  }
}