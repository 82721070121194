/**
 * @file: VehicleInspectionTaskQuotationService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleInspectionTaskQuotation,
  VehicleInspectionTaskQuotationDetail,
  VehicleInspectionTaskQuotationIssue,
  VehicleInspectionTaskQuotationListFilter,
} from 'model';

export class VehicleInspectionTaskQuotationServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?:
      | Partial<VehicleInspectionTaskQuotationListFilter>
      | null
      | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleInspectionTaskQuotation>
      : VehicleInspectionTaskQuotation[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-inspection-task-quotations',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleInspectionTaskQuotation | null> {
    return await this.api
      .get()
      .url('/vehicle-inspection-task-quotations/:id', { id })
      .future();
  }

  async create(
    vehicleInspectionTaskQuotation: Partial<VehicleInspectionTaskQuotation>,
  ): Promise<VehicleInspectionTaskQuotation> {
    return await this.api
      .post()
      .url('/vehicle-inspection-task-quotations')
      .data(vehicleInspectionTaskQuotation)
      .future();
  }

  async update(
    vehicleInspectionTaskQuotation: Partial<VehicleInspectionTaskQuotation>,
  ): Promise<VehicleInspectionTaskQuotation> {
    return await this.api
      .put()
      .url('/vehicle-inspection-task-quotations/:id', {
        id: vehicleInspectionTaskQuotation.id,
      })
      .data(vehicleInspectionTaskQuotation)
      .future();
  }

  async delete(id: number): Promise<void> {
    await this.api
      .delete()
      .url('/vehicle-inspection-task-quotations/:id', { id })
      .future();
  }

  async getDetailsByQuotationId(
    id: number,
    recursive = false,
  ): Promise<VehicleInspectionTaskQuotationDetail[]> {
    return await this.api
      .get()
      .url('/vehicle-inspection-task-quotations/:id/details', { id, recursive })
      .future();
  }

  async createVehicleInspectionTaskQuotationDetail(
    detail: Partial<VehicleInspectionTaskQuotationDetail>,
  ): Promise<VehicleInspectionTaskQuotationDetail> {
    const id = detail.quotationId;
    if (!id) {
      throw new Error('detail.quotationId is required. ');
    }
    return await this.api
      .post()
      .url('/vehicle-inspection-task-quotations/:id/details')
      .data(detail)
      .future();
  }

  async updateVehicleInspectionTaskQuotationDetail(
    detail: VehicleInspectionTaskQuotationDetail,
  ): Promise<VehicleInspectionTaskQuotationDetail> {
    const id = detail.quotationId;
    if (!id) {
      throw new Error('detail.quotationId is required. ');
    }
    return await this.api
      .put()
      .url('/vehicle-inspection-task-quotations/:id/details')
      .data(detail)
      .future();
  }

  async deleteVehicleInspectionTaskQuotationDetail(
    quotationId: number | VehicleInspectionTaskQuotationDetail,
    detailId?: number,
  ): Promise<void> {
    if (quotationId && typeof quotationId === 'object') {
      detailId = quotationId.id;
      if (!quotationId.quotationId) {
        throw new Error('quotationId cannot be null or undefined');
      }
      quotationId = quotationId.quotationId;
    }
    if (!quotationId || !detailId) {
      throw new Error('quotationId and detailId are both required');
    }
    await this.api
      .delete()
      .url(
        '/vehicle-inspection-task-quotations/:quotationId/details/:detailId',
        { quotationId, detailId },
      )
      .future();
  }

  async getIssuesByQuotationId(
    id: number,
    recursive = false,
  ): Promise<VehicleInspectionTaskQuotationIssue[]> {
    return await this.api
      .get()
      .url('/vehicle-inspection-task-quotations/:id/issues', { id, recursive })
      .future();
  }

  async createVehicleInspectionTaskQuotationIssue(
    issue: Partial<VehicleInspectionTaskQuotationIssue>,
  ): Promise<VehicleInspectionTaskQuotationIssue> {
    const id = issue.quotationId;
    if (!id) {
      throw new Error('issue.quotationId is required. ');
    }
    return await this.api
      .post()
      .url('/vehicle-inspection-task-quotations/:id/issues')
      .data(issue)
      .future();
  }

  async updateVehicleInspectionTaskQuotationIssue(
    issue: VehicleInspectionTaskQuotationIssue,
  ): Promise<VehicleInspectionTaskQuotationIssue> {
    const id = issue.quotationId;
    if (!id) {
      throw new Error('issue.quotationId is required. ');
    }
    return await this.api
      .put()
      .url('/vehicle-inspection-task-quotations/:id/issues')
      .data(issue)
      .future();
  }

  async deleteVehicleInspectionTaskQuotationIssue(
    quotationId: number | VehicleInspectionTaskQuotationIssue,
    issueId?: number,
  ): Promise<void> {
    if (quotationId && typeof quotationId === 'object') {
      issueId = quotationId.id;
      if (!quotationId.quotationId) {
        throw new Error('quotationId cannot be null or undefined');
      }
      quotationId = quotationId.quotationId;
    }
    if (!quotationId || !issueId) {
      throw new Error('quotationId and issueId are both required');
    }
    await this.api
      .delete()
      .url('/vehicle-inspection-task-quotations/:quotationId/issues/:issueId', {
        quotationId,
        issueId,
      })
      .future();
  }
}
