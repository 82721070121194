import {
  QuotationTemplateCategory,
  QuotationTemplateCategoryStaged,
  QuotationTemplateConfig,
  QuotationTemplateConfigStaged,
  QuotationTemplateGroup,
  QuotationTemplateGroupStaged,
  QuotationTemplateSubject,
  QuotationTemplateSubjectStaged,
} from 'model';

export function isCategoryConfigValid(category: QuotationTemplateCategory) {
  if (category.name == null || category.name.trim().length === 0) {
    return false;
  }
  for (const group of category.groups) {
    if (!group.name || group.name.trim().length === 0) {
      return false;
    }
    for (const subject of group.subjects) {
      if (!subject.name || subject.name.trim().length === 0) {
        return false;
      }
      for (const item of subject.items) {
        if (!item.name || item.name.trim().length === 0) {
          return false;
        }
      }
    }
  }
  return true;
}

export function isQuotationTemplateConfigValid(
  templateConfig: QuotationTemplateConfigStaged,
) {
  return templateConfig.categories.every(isCategoryConfigValid);
}

export function quotationTemplateConfigToStaged(
  quotationTemplateConfig: QuotationTemplateConfig,
): QuotationTemplateConfigStaged {
  return {
    categories:
      quotationTemplateConfig.categories.map<QuotationTemplateCategoryStaged>(
        category => ({
          id: category.id,
          name: category.name,
          icon: category.icon,
          description: category.description,
          expanded: true,
          groups: category.groups.map<QuotationTemplateGroupStaged>(group => ({
            id: group.id,
            categoryId: category.id,
            name: group.name,
            icon: group.icon,
            description: group.description,
            subjects: group.subjects.map<QuotationTemplateSubjectStaged>(
              subject => ({
                id: subject.id,
                groupId: group.id,
                name: subject.name,
                code: subject.code,
                pyInitial: subject.pyInitial,
                keywords: subject.keywords,
                items: subject.items,
              }),
            ),
          })),
        }),
      ),
  };
}

export function quotationTemplateConfigFromStaged(
  staged: QuotationTemplateConfigStaged,
): QuotationTemplateConfig {
  return {
    version: '1.0',
    categories: staged.categories.map<QuotationTemplateCategory>(category => ({
      id: category.id,
      name: category.name,
      icon: category.icon,
      description: category.description,
      groups: category.groups.map<QuotationTemplateGroup>(group => ({
        id: group.id,
        name: group.name,
        icon: group.icon,
        description: group.description,
        subjects: group.subjects.map<QuotationTemplateSubject>(subject => ({
          id: subject.id,
          name: subject.name,
          code: subject.code,
          pyInitial: subject.pyInitial,
          keywords: subject.keywords,
          items: subject.items,
        })),
      })),
    })),
  };
}
