import { showAppLoading } from 'app/duck/actions';
import { quotationTplActions } from 'app/inspection/duck/actions';
import { useQuotationTemplateConfigContext } from 'app/inspection/quotation-template-config/Context';
import {
  isQuotationTemplateConfigValid,
  quotationTemplateConfigFromStaged,
} from 'app/inspection/quotation-template-config/util';
import { memo, useCallback, useRef } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { quotationTemplateConfigService } from 'services';
import { getString } from 'shared/components';
import { Button } from 'shared/metronic/components';
import { usePersistFn } from 'utils/usePersistFn';

export const Actions = memo(() => {
  const { staged, state } = useQuotationTemplateConfigContext();
  const dispatch = useDispatch();

  const onReset = useCallback(() => {
    if (confirm(getString('quotation_tpl.changes.reset.confirm'))) {
      dispatch(quotationTplActions.resetChanges());
    }
  }, [dispatch]);

  const isSaving = useRef(false);

  const onSave = usePersistFn(async () => {
    if (isSaving.current) {
      return;
    }
    try {
      isSaving.current = true;
      dispatch(
        showAppLoading({
          message: getString('quotation_tpl.changes.saving'),
          status: 'loading',
        }),
      );
      const quotationTemplateConfig = quotationTemplateConfigFromStaged(staged);
      await quotationTemplateConfigService.saveQuotationTemplateConfig(
        quotationTemplateConfig,
      );
      dispatch(quotationTplActions.saveSuccessful(quotationTemplateConfig));
      dispatch(
        showAppLoading({
          message: getString('quotation_tpl.changes.save_success'),
          status: 'success',
          timeout: 3000,
        }),
      );
    } catch (e) {
      console.error(e);
      dispatch(
        showAppLoading({
          message: getString('quotation_tpl.changes.save_error', {
            msg: e.message,
          }),
          status: 'error',
          timeout: 3000,
        }),
      );
    } finally {
      isSaving.current = false;
    }
  });

  if (!state.dirty) {
    return null;
  }

  return (
    <div className="quotation-tpl__actions">
      <div className="quotation-tpl__actions-msg">
        <i className="fa fa-info-circle" />
        <Translate id="quotation_tpl.changes.tip" />
      </div>
      <Button color="danger" pill small onClick={onReset}>
        <Translate id="quotation_tpl.changes.btn.reset" />
      </Button>
      <Button
        color="primary"
        pill
        small
        disabled={!isQuotationTemplateConfigValid(staged)}
        onClick={onSave}
      >
        <Translate id="quotation_tpl.changes.btn.save" />
      </Button>
    </div>
  );
});
