import { QuotationTemplateConfigState } from 'app/inspection/duck/states';
import { Draft } from 'immer';
import {
  createAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { StandardAction } from 'lib/duck/interfaces';
import { QuotationTemplateConfig } from 'model';
import { quotationTemplateConfigService } from 'services';
import { ActionTypes } from '../types';

const actions = createAsyncActionCreators(
  'inspection.quotation-template-config',
  {
    shouldFetchOnInvalidate: true,
    fetchHandler: () =>
      quotationTemplateConfigService.getQuotationTemplateConfig(),
  },
);

export function makeDefaultQuotationTemplateConfig(): QuotationTemplateConfig {
  return {
    version: '1.0',
    categories: [],
  };
}

export class QuotationTemplateConfigActionCreators {
  fetchQuotationTemplateConfig = actions.fetch;
  refreshQuotationTemplateConfig = actions.invalidate;

  quotationTemplateConfigLoaded(
    quotationTemplateConfig: QuotationTemplateConfig,
  ) {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigLoaded,
      quotationTemplateConfig,
    );
  }

  addCategory(): StandardAction<any> {
    return createStandardAction(ActionTypes.QuotationTemplateConfigAddCategory);
  }

  editCategory(id: string): StandardAction<string> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigEditCategory,
      id,
    );
  }

  editCategoryChanged(name: string): StandardAction<string> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigEditCategoryChanged,
      name,
    );
  }

  editCategoryCommitted(name?: string): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigEditCategoryCommitted,
      name,
    );
  }

  editCategoryCancelled(): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigEditCategoryCancelled,
    );
  }

  removeCategory(id: string): StandardAction<string> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigRemoveCategory,
      id,
    );
  }

  commitRemoveCategory(): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCommitRemoveCategory,
    );
  }

  cancelRemoveCategory(): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCancelRemoveCategory,
    );
  }

  expandCategory(id: string): StandardAction<string> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigExpandCategory,
      id,
    );
  }

  collapseCategory(id: string): StandardAction<string> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCollapseCategory,
      id,
    );
  }

  categoryMoved(id: string, from: number, to: number): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCategoryMoved,
      {
        id,
        from,
        to,
      },
    );
  }

  addGroup(categoryId: string): StandardAction<string> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigAddGroup,
      categoryId,
    );
  }

  editGroup(id: string, name: string): StandardAction<any> {
    return createStandardAction(ActionTypes.QuotationTemplateConfigEditGroup, {
      id,
      name,
    });
  }

  editGroupChanged(name: string): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigEditGroupChanged,
      name,
    );
  }

  editGroupCommitted(name?: string): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigEditGroupCommitted,
      name,
    );
  }

  editGroupCancelled(): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigEditGroupCancelled,
    );
  }

  removeGroup(groupId: string): StandardAction<string> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigRemoveGroup,
      groupId,
    );
  }

  commitRemoveGroup(): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCommitRemoveGroup,
    );
  }

  cancelRemoveGroup(): StandardAction<any> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigCancelRemoveGroup,
    );
  }

  groupMoved(id: string, from: number, to: number): StandardAction<any> {
    return createStandardAction(ActionTypes.QuotationTemplateConfigGroupMoved, {
      id,
      from,
      to,
    });
  }

  groupSelected(id: string): StandardAction<string> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigGroupSelected,
      id,
    );
  }

  allGroupsSelected(categoryId: string): StandardAction<string> {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigAllGroupsSelected,
      categoryId,
    );
  }

  applyChangesToTemplateConfig(
    recipe: (state: Draft<QuotationTemplateConfigState>) => void,
    options?: { markAsDirty?: boolean },
  ) {
    return createStandardAction(ActionTypes.QuotationTemplateConfigChanged, {
      recipe,
      options,
    });
  }

  resetChanges(withConfig?: QuotationTemplateConfig) {
    return createStandardAction(
      ActionTypes.ResetQuotationTemplateConfigChanges,
      withConfig,
    );
  }

  saveSuccessful(res: QuotationTemplateConfig) {
    return createStandardAction(
      ActionTypes.QuotationTemplateConfigSaveSuccess,
      res,
    );
  }
}

export const quotationTplActions = new QuotationTemplateConfigActionCreators();
