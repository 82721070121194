import { SubjectList } from 'app/inspection/quotation-template-config/subject/SubjectList';
import { memo } from 'react';
import { useQuotationTemplateConfigContext } from './Context';
import { EmptyDetail } from './EmptyDetail';

export const Detail = memo(() => {
  const { state, editable } = useQuotationTemplateConfigContext();

  const selectedGroup = state.selectedGroupId
    ? state.groupMap.get(state.selectedGroupId)
    : null;

  return (
    <div className="quotation-tpl__detail-panel">
      {selectedGroup == null ? (
        <EmptyDetail />
      ) : (
        <>
          <SubjectList group={selectedGroup} editable={editable} />
        </>
      )}
    </div>
  );
});
