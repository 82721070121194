import { quotationTplActions } from 'app/inspection/duck/actions';
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ConfirmModal } from '../ConfirmModal';
import { useQuotationTemplateConfigContext } from '../Context';

export const ConfirmRemoveCategoryModal = memo(() => {
  const dispatch = useDispatch();
  const { state } = useQuotationTemplateConfigContext();

  const onConfirm = useCallback(() => {
    dispatch(quotationTplActions.commitRemoveCategory());
  }, [dispatch]);

  const onCancel = useCallback(() => {
    dispatch(quotationTplActions.cancelRemoveCategory());
  }, [dispatch]);

  return (
    <ConfirmModal
      open={state.categoryIdBeingRemoved != null}
      title="quotation_tpl.category.modal.confirm_remove.title"
      content="quotation_tpl.category.modal.confirm_remove.msg"
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
});
