import { AreaTree } from 'model';
import { Component, ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  EntityWithAgentBounded,
  FormElementAgentPicker,
  FormElementMultiTextInput,
} from 'shared/components';
import { FormProductAgentPicker } from 'shared/components/EntityEditorForm/elements/ProductAgentPicker';
import { FormServiceDurationPicker } from 'shared/components/EntityEditorForm/elements/ServiceDurationPicker';
import { FormServiceEditionPicker } from 'shared/components/EntityEditorForm/elements/ServiceEditionPicker';
import { getString, StringLabel } from 'shared/components/StringLabel';
import {
  EntityWithAreaProps,
  EntityWithProductAgentBounded,
  EntityWithServiceEditionProps,
  FormElement,
  FormElementAreaPicker,
  FormElementCheckbox,
  FormElementCheckboxList,
  FormElementCustom,
  FormElementFile,
  FormElementGroup,
  FormElementHtml,
  FormElementMedias,
  FormElementProductAgentPicker,
  FormElementRadioList,
  FormElementReactSelect,
  FormElementSelect,
  FormElementServiceDurationPicker,
  FormElementServiceEditionPicker,
  FormElementSwitch,
  FormElementText,
  FormElementTextArea,
} from '../types';
import {
  FormAgentPicker,
  FormAreaPicker,
  FormCheckbox,
  FormCheckboxList,
  FormFilePicker,
  FormGroup,
  FormHtmlEditor,
  FormMediasPicker,
  FormRadioList,
  FormReactSelect,
  FormSelect,
  FormSwitch,
  FormTextArea,
  FormTextInput,
  MultiTextInput,
} from './';

interface Props<T> {
  element: FormElement<T>;
  value: any;
  disabled?: boolean;
  autocomplete?: boolean;
  useUncontrolled?: boolean;
  onGetExtraInfo: (() => any) | undefined | null;
  onGetEntity: () => T | Partial<T>;
  onGetAreas: () => AreaTree | null | undefined;
  onChange: (values: Partial<T>) => void;
}

export class FormElementRenderer<T> extends Component<Props<T>> {
  render() {
    const { element } = this.props;

    const entity = this.props.onGetEntity();
    const extra = this.props.onGetExtraInfo
      ? this.props.onGetExtraInfo()
      : undefined;

    if (element.hidden) {
      if (typeof element.hidden === 'function') {
        if (element.hidden(entity, extra)) return null;
      } else {
        return null;
      }
    }

    const control = this.internalRenderControl(entity);
    if (!control) return null;

    return (
      <div className="form-group" key={element.key || (element.prop as string)}>
        {element.label && element.type !== 'checkbox' && (
          <label>
            <StringLabel value={element.label} />
          </label>
        )}
        {control}
        {element.helpText &&
          (typeof element.helpText === 'string' ? (
            <span className="m-form__help">
              <ReactMarkdown>{getString(element.helpText)}</ReactMarkdown>
            </span>
          ) : typeof element.helpText === 'function' ? (
            element.helpText(entity, extra)
          ) : (
            element.helpText
          ))}
      </div>
    );
  }

  internalRenderControl(entity: T | Partial<T>) {
    const { element } = this.props;
    const { type } = element;

    const value = entity[element.prop] as any;

    if (/^(text|number|password|email|phone|datetime-local)$/.test(type)) {
      return this.renderTextControl(element as FormElementText<T>, value);
    }

    if (type === 'multi-text-input') {
      return this.renderMultiTextInput(
        element as FormElementMultiTextInput<T>,
        value,
      );
    }

    if (type === 'textarea') {
      return this.renderTextArea(element as FormElementTextArea<T>, value);
    }

    if (type === 'html') {
      return this.renderHtmlEditor(element as FormElementHtml<T>, value);
    }

    if (type === 'image' || type === 'video') {
      return this.renderFilePicker(element as FormElementFile<T>, value);
    }

    if (type === 'medias') {
      return this.renderMediasPicker(element as FormElementMedias<T>, value);
    }

    if (type === 'radiolist') {
      return this.renderRadioList(element as FormElementRadioList<T>, value);
    }

    if (type === 'checkboxlist') {
      return this.renderCheckboxList(
        element as FormElementCheckboxList<T>,
        value,
      );
    }

    if (type === 'select') {
      return this.renderSelect(element as FormElementSelect<T>, value);
    }

    if (type === 'reactselect') {
      return this.renderReactSelect(
        element as FormElementReactSelect<T>,
        value,
      );
    }

    if (type === 'checkbox') {
      return this.renderCheckbox(
        element as FormElementCheckbox<T>,
        value || false,
      );
    }

    if (type === 'switch') {
      return this.renderSwitch(element as FormElementSwitch<T>, value || false);
    }

    if (type === 'area') {
      return this.renderAreaPicker(
        element as FormElementAreaPicker<T & EntityWithAreaProps>,
        entity as T & EntityWithAreaProps,
      );
    }

    if (type === 'service-duration') {
      return this.renderServiceDurationPicker(
        element as FormElementServiceDurationPicker<T>,
        entity as T,
        value,
      );
    }

    if (type === 'custom') {
      return this.renderCustom(element as FormElementCustom<T>);
    }

    if (type === 'element-group') {
      return this.renderElementGroup(element as FormElementGroup<T>);
    }

    if (type === 'agent') {
      return this.renderAgentPicker(
        element as FormElementAgentPicker<T & EntityWithAgentBounded>,
        entity as T & EntityWithAgentBounded,
      );
    }

    if (type === 'product-agent') {
      return this.renderProductAgentPicker(
        element as FormElementProductAgentPicker<
          T & EntityWithProductAgentBounded
        >,
        entity as T & EntityWithProductAgentBounded,
      );
    }

    if (type === 'service-edition') {
      return this.renderServiceEditionPicker(
        element as FormElementServiceEditionPicker<
          T & EntityWithServiceEditionProps
        >,
        entity as T & EntityWithServiceEditionProps,
      );
    }

    return null;
  }

  renderTextControl(element: FormElementText<T>, value: any): ReactNode {
    const { autocomplete } = this.props;
    return (
      <FormTextInput
        element={element}
        {...this.getCommonElementAttrs(element)}
        autocomplete={autocomplete}
        useUncontrolled={this.props.useUncontrolled}
        value={value}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderMultiTextInput(
    element: FormElementMultiTextInput<T>,
    value: any,
  ): ReactNode {
    const { disabled } = this.props;
    const values = value ? element.deserialize(value) : [];
    return (
      <MultiTextInput
        values={values}
        disabled={disabled}
        placeholder={
          element.placeholder ? getString(element.placeholder) : undefined
        }
        options={element.options}
        isValidInput={element.isValidInput}
        onChange={updatedValues => {
          const changes: { [K in keyof T]?: T[K] } = {};
          changes[element.prop] = element.serialize(updatedValues) as any;
          this.props.onChange(changes);
        }}
      />
    );
  }

  renderTextArea(element: FormElementTextArea<T>, value: any): ReactNode {
    const { autocomplete } = this.props;
    return (
      <FormTextArea
        element={element}
        {...this.getCommonElementAttrs(element)}
        autocomplete={autocomplete}
        useUncontrolled={this.props.useUncontrolled}
        value={value}
        valueToString={element.valueToString}
        valueFromString={element.valueFromString}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderFilePicker(element: FormElementFile<T>, value: any): ReactNode {
    const { disabled } = this.props;
    return (
      <FormFilePicker
        element={element}
        value={value}
        disabled={disabled}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderMediasPicker(element: FormElementMedias<T>, value: any): ReactNode {
    const { disabled } = this.props;
    const medias = value ? element.deserialize(value) : [];
    return (
      <FormMediasPicker
        element={element}
        medias={medias}
        disabled={disabled}
        previewWidth={element.previewWidth}
        previewHeight={element.previewHeight}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={updatedMedias => {
          const changes: { [K in keyof T]?: T[K] } = {};
          changes[element.prop] = element.serialize(updatedMedias) as any;
          this.props.onChange(changes);
        }}
      />
    );
  }

  renderHtmlEditor(element: FormElementHtml<T>, value: any): ReactNode {
    const { disabled } = this.props;
    return (
      <FormHtmlEditor
        element={element}
        value={value}
        disabled={disabled}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderCheckbox(element: FormElementCheckbox<T>, value: boolean): ReactNode {
    const { disabled } = this.props;
    return (
      <FormCheckbox
        element={element}
        value={value}
        disabled={disabled}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderSwitch(element: FormElementSwitch<T>, value: boolean): ReactNode {
    const { disabled } = this.props;
    return (
      <FormSwitch
        element={element}
        value={value}
        disabled={disabled}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderRadioList(element: FormElementRadioList<T>, value: any): ReactNode {
    const entity = this.props.onGetEntity();
    const extra = this.props.onGetExtraInfo?.();
    const options =
      typeof element.options === 'function'
        ? element.options({ entity, value, extra })
        : element.options;
    const { disabled } = this.props;
    return (
      <FormRadioList
        element={element}
        value={value}
        options={options}
        disabled={disabled}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderCheckboxList(
    element: FormElementCheckboxList<T>,
    value: any,
  ): ReactNode {
    const entity = this.props.onGetEntity();
    const extra = this.props.onGetExtraInfo?.();
    const options =
      typeof element.options === 'function'
        ? element.options({ entity, value, extra })
        : element.options;
    const { disabled } = this.props;
    return (
      <FormCheckboxList
        element={element}
        value={value}
        options={options}
        disabled={disabled}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderSelect(element: FormElementSelect<T>, value: any): ReactNode {
    const entity = this.props.onGetEntity();
    const extra = this.props.onGetExtraInfo?.();
    const options =
      (typeof element.options === 'function'
        ? element.options({ entity, value, extra })
        : element.options) || [];
    const { disabled } = this.props;
    return (
      <FormSelect
        element={element}
        options={options}
        value={value}
        autocomplete={this.props.autocomplete}
        disabled={disabled}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderReactSelect(element: FormElementReactSelect<T>, value: any): ReactNode {
    const { disabled } = this.props;
    return (
      <FormReactSelect
        element={element}
        value={value}
        disabled={disabled}
        onGetEntity={this.props.onGetEntity}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderAreaPicker(
    element: FormElementAreaPicker<T & EntityWithAreaProps>,
    entity: (T & EntityWithAreaProps) | Partial<T & EntityWithAreaProps>,
  ) {
    const areas = this.props.onGetAreas();
    const { disabled } = this.props;
    return (
      <FormAreaPicker
        element={element}
        areas={areas}
        provinceId={entity.provinceId}
        cityId={entity.cityId}
        districtId={entity.districtId}
        disabled={disabled}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderServiceDurationPicker(
    element: FormElementServiceDurationPicker<T>,
    entity: T | Partial<T>,
    value: any,
  ) {
    const disabled = (() => {
      if (this.props.disabled) return true;
      if (typeof element.disabled === 'boolean') {
        return element.disabled;
      }
      if (typeof element.disabled === 'function') {
        return element.disabled(entity, this.props.onGetExtraInfo?.());
      }
      return undefined;
    })();
    return (
      <FormServiceDurationPicker
        element={element}
        disabled={disabled}
        required={element.required}
        value={value}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderAgentPicker(
    element: FormElementAgentPicker<T & EntityWithAgentBounded>,
    entity: (T & EntityWithAgentBounded) | Partial<T & EntityWithAgentBounded>,
  ) {
    const { disabled } = this.getCommonElementAttrs(element);
    return (
      <FormAgentPicker
        element={element}
        disabled={disabled}
        agentId={entity[element.prop] as any}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderProductAgentPicker(
    element: FormElementProductAgentPicker<T & EntityWithProductAgentBounded>,
    entity:
      | (T & EntityWithProductAgentBounded)
      | Partial<T & EntityWithProductAgentBounded>,
  ) {
    const { disabled } = this.getCommonElementAttrs(element);
    return (
      <FormProductAgentPicker
        element={element}
        disabled={disabled}
        agentId={entity[element.prop] as any}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderServiceEditionPicker(
    element: FormElementServiceEditionPicker<T & EntityWithServiceEditionProps>,
    entity:
      | (T & EntityWithServiceEditionProps)
      | Partial<T & EntityWithServiceEditionProps>,
  ) {
    const { disabled } = this.getCommonElementAttrs(element);
    return (
      <FormServiceEditionPicker
        element={element}
        disabled={disabled}
        editionId={entity[element.prop] as any}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onChange={this.props.onChange}
      />
    );
  }

  renderCustom(element: FormElementCustom<T>) {
    const extra = this.props.onGetExtraInfo?.();
    return (element.render && element.render(extra)) || null;
  }

  renderElementGroup(group: FormElementGroup<T>) {
    const entity = this.props.onGetEntity();
    const { disabled } = this.props;
    return (
      <FormGroup
        element={group}
        value={entity[group.prop]}
        autocomplete={this.props.autocomplete}
        disabled={disabled}
        useUncontrolled={this.props.useUncontrolled}
        onGetExtraInfo={this.props.onGetExtraInfo}
        onGetEntity={this.props.onGetEntity}
        onGetAreas={this.props.onGetAreas}
        onChange={this.props.onChange}
      />
    );
  }

  private getCommonElementAttrs(
    element: Pick<FormElement<T>, 'disabled' | 'readonly'>,
  ) {
    const entity = this.props.onGetEntity();

    const extra = this.props.onGetExtraInfo?.();

    let disabled: boolean | undefined = undefined;
    if (this.props.disabled === true) {
      disabled = true;
    } else {
      if (typeof element.disabled === 'function') {
        disabled = element.disabled(entity, extra);
      } else {
        disabled = element.disabled;
      }
    }

    let readOnly: boolean | undefined = undefined;
    if (typeof element.readonly === 'function') {
      readOnly = element.readonly(entity, extra);
    } else {
      readOnly = element.readonly;
    }

    return { disabled, readOnly };
  }
}
