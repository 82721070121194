import React from 'react';
import {
  EntityListProps,
  EntityListComponentClassBuilder,
  getString,
  Checkmark,
  ServiceEditionTypeLabel,
  ServiceEditionVariantTypeLabel,
} from 'shared/components';
import { ServiceEdition, ServiceEditionListFilter, AclObjectList } from 'model';
import { Translate } from 'react-localize-redux';
import { serviceEditionActions } from '../duck/actions';
import { formatDate } from 'utils';
import {
  ServiceEditionTypeOptions,
  ServiceEditionVariantTypeOptions,
} from 'model/EnumOptions';

const kAllowUpdateCoreProps = false;

interface Props
  extends EntityListProps<ServiceEdition, ServiceEditionListFilter> {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  ServiceEdition,
  ServiceEditionListFilter,
  number,
  Props
>();

export const ServiceEditionList = componentClassBuilder
  .i18nPrefix('service_edition')
  .accessRights({
    full: AclObjectList.ServiceEditionFullAccess,
    readonly: AclObjectList.ServiceEditionReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="settings.breadcrumb.it" /> },
    { text: <Translate id="settings.breadcrumb.service_editions" /> },
  ])
  .entities(state => state.settings.serviceEditions)
  .actions(serviceEditionActions)
  .editor(builder =>
    builder
      .select({
        prop: 'type',
        options: ServiceEditionTypeOptions,
        label: 'service_edition.editor.label.type',
        placeholder: 'service_edition.editor.placeholder.type',
        disabled(entity) {
          return !kAllowUpdateCoreProps && entity.id != null;
        },
      })
      .select({
        prop: 'variant',
        options: ServiceEditionVariantTypeOptions,
        label: 'service_edition.editor.label.variant',
        placeholder: 'service_edition.editor.placeholder.variant',
        disabled(entity) {
          return !kAllowUpdateCoreProps && entity.id != null;
        },
      })
      .checkbox({
        prop: 'isTrialEdition',
        label: 'service_edition.editor.label.is_trial_edition',
        disabled(entity) {
          return !kAllowUpdateCoreProps && entity.id != null;
        },
      })
      .text({
        type: 'number',
        prop: 'userAccountLimit',
        label: 'service_edition.editor.label.user_account_limit',
        placeholder: 'service_edition.editor.placeholder.user_account_limit',
        helpText: 'service_edition.editor.help_text.user_account_limit',
        disabled(entity) {
          return !kAllowUpdateCoreProps && entity.id != null;
        },
      })
      .checkbox({
        prop: 'isTvVersionEnabled',
        label: 'service_edition.editor.label.is_tv_version_enabled',
      })
      .checkbox({
        prop: 'isDataV1Enabled',
        label: 'service_edition.editor.label.is_data_v1_enabled',
      })
      .checkbox({
        prop: 'isNewBiEnabled',
        label: 'service_edition.editor.label.is_new_bi_enabled',
      })
      .checkbox({
        prop: 'isNewMarketingEnabled',
        label: 'service_edition.editor.label.is_new_marketing_enabled',
      })
      .textArea({
        prop: 'remark',
        label: 'service_edition.editor.label.remark',
        placeholder: 'service_edition.editor.placeholder.remark',
      }),
  )
  .addActionButtons(['edit', 'remove'])
  .columns([
    {
      prop: 'name',
      width: 150,
      text: 'col.name',
    },
    {
      prop: 'type',
      width: 80,
      align: 'center',
      text: 'service_edition.col.type',
      render: ({ type }) => <ServiceEditionTypeLabel value={type} />,
    },
    {
      prop: 'variant',
      width: 80,
      align: 'center',
      text: 'service_edition.col.variant',
      render: ({ variant }) => (
        <ServiceEditionVariantTypeLabel value={variant} />
      ),
    },
    {
      prop: 'sku',
      width: 120,
      text: 'service_edition.col.sku',
      render: ({ sku }) => (
        <span
          style={{
            fontSize: '0.8rem',
            whiteSpace: 'nowrap',
            textDecoration: 'underline',
            textDecorationStyle: 'dotted',
          }}
        >
          {sku}
        </span>
      ),
    },
    {
      prop: 'isTrailEdition',
      width: 40,
      align: 'center',
      text: 'service_edition.col.is_trial_edition',
      render: ({ isTrialEdition }) => <Checkmark value={isTrialEdition} />,
    },
    {
      prop: 'userAccountLimit',
      width: 40,
      text: 'service_edition.col.user_account_limit',
      render: (serviceEdition: ServiceEdition) => {
        return serviceEdition.userAccountLimit > 0
          ? `${serviceEdition.userAccountLimit}`
          : '-';
      },
    },
    {
      prop: 'isTvVersionEnabled',
      width: 40,
      text: 'service_edition.col.is_tv_version_enabled',
      align: 'center',
      render: ({ isTvVersionEnabled }) => (
        <Checkmark value={isTvVersionEnabled} />
      ),
    },
    {
      prop: 'isDataV1Enabled',
      width: 80,
      text: 'service_edition.col.is_data_v1_enabled',
      align: 'center',
      render: ({ isDataV1Enabled }) => <Checkmark value={isDataV1Enabled} />,
    },
    {
      prop: 'isNewBiEnabled',
      width: 60,
      text: 'service_edition.col.is_new_bi_enabled',
      align: 'center',
      render: ({ isNewBiEnabled }) => <Checkmark value={isNewBiEnabled} />,
    },
    {
      prop: 'isNewMarketingEnabled',
      width: 60,
      text: 'service_edition.col.is_new_marketing_enabled',
      align: 'center',
      render: ({ isNewMarketingEnabled }) => (
        <Checkmark value={isNewMarketingEnabled} />
      ),
    },
    {
      prop: 'createdAt',
      width: 100,
      text: 'col.created_at',
      align: 'center',
      render: ({ createdAt }) => formatDate(createdAt),
    },
  ])
  .onAdd(edition => {
    edition.isTrialEdition = false;
    edition.isTvVersionEnabled = true;
    edition.isDataV1Enabled = true;
    edition.isNewBiEnabled = false;
    edition.isNewMarketingEnabled = false;
  })
  .validate(entity => {
    let msg = '';

    if (!entity.type) {
      msg = 'type_required';
    } else if (!entity.variant) {
      msg = 'variant_required';
    } else if (entity.userAccountLimit == null) {
      msg = 'user_account_limit_required';
    } else if (entity.userAccountLimit < 0) {
      msg = 'invalid_user_account_limit';
    }

    if (msg) {
      throw new Error(getString(`service_edition.editor.error.${msg}`));
    }
  })
  .getClass();
