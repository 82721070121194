import { ChangeEvent, ReactNode, useCallback } from 'react';

interface CheckButtonProps {
  label: string | ReactNode;
  value?: any;
  checked: boolean;
  disabled?: boolean;
  onChange: (
    value: any,
    checked: boolean,
    e: ChangeEvent<HTMLInputElement>,
  ) => void;
}

export function CheckButton(props: CheckButtonProps) {
  const { value, checked, label, disabled, onChange, ...others } = props;
  const handler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(value, e.target.checked, e);
    },
    [onChange, value],
  );
  return (
    <label className="m-checkbox m-checkbox--solid m-checkbox--brand">
      <input
        type="checkbox"
        checked={checked || false}
        disabled={disabled}
        onChange={handler}
        {...others}
      />
      {label}
      <span />
    </label>
  );
}
