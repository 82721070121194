import { defineActionTypes } from 'lib';

export const ActionTypes = defineActionTypes('app/inspection', {
  NodeSelected: '',
  NodeExpanded: '',
  NodeCollapsed: '',
  NodeMouseEnter: '',
  NodeMouseLeave: '',

  FilterChanged: '',
  SiteKeywordFilterChanged: '',
  InventoryManagerSetTargetCategoryId: '',
  SetExpandedCollapsedNodes: '',

  BatchSetType: '',
  RequestBatchSetType: '',
  BatchSetTypeSuccess: '',
  BatchSetTypeError: '',

  BatchSetModule: '',
  RequestBatchSetModule: '',
  BatchSetModuleSuccess: '',
  BatchSetModuleError: '',

  ImportInventory: '',
  ImportInventorySuccess: '',
  ImportInventoryFailed: '',

  TemplateFilterChanged: '',

  TemplateDetailReady: '',
  TemplateDetailUpdated: '',
  TemplateDetailAddCategory: '',
  TemplateDetailEditCategory: '',
  TemplateDetailEditCategoryChanged: '',
  TemplateDetailEditCategoryCommitted: '',
  TemplateDetailEditCategoryCancelled: '',
  TemplateDetailRemoveCategory: '',
  TemplateDetailCommitRemoveCategory: '',
  TemplateDetailCancelRemoveCategory: '',
  TemplateDetailExpandCategory: '',
  TemplateDetailCollapseCategory: '',
  TemplateDetailAddGroup: '',
  TemplateDetailEditGroup: '',
  TemplateDetailEditGroupChanged: '',
  TemplateDetailEditGroupCommitted: '',
  TemplateDetailEditGroupCancelled: '',
  TemplateDetailRemoveGroup: '',
  TemplateDetailCommitRemoveGroup: '',
  TemplateDetailCancelRemoveGroup: '',
  TemplateDetailCategoryMoved: '',
  TemplateDetailGroupSelected: '',
  TemplateDetailAllGroupsSelected: '',
  TemplateDetailGroupMoved: '',
  TemplateDetailAddSite: '',
  TemplateDetailRemoveSite: '',
  TemplateDetailAddSites: '',
  TemplateDetailRemoveSites: '',
  TemplateDetailRemoveSitesCommitted: '',
  TemplateDetailRemoveSitesCancelled: '',
  TemplateDetailShowSiteList: '',
  TemplateDetailHideSiteList: '',
  TemplateDetailShowPreview: '',
  TemplateDetailHidePreview: '',
  TemplateDetailSiteMoved: '',
  TemplateDetailSiteEnabled: '',
  TemplateDetailSiteDisabled: '',
  TemplateDetailSiteDefaultHiddenSet: '',
  TemplateDetailSiteRequiredSet: '',
  TemplateDetailSiteSelected: '',
  TemplateDetailSiteDeselected: '',
  TemplateDetailToggleSelectAll: '',
  TemplateDetailSiteListKeywordChange: '',
  TemplateDetailEditSiteRels: '',
  TemplateDetailEndEditSiteRels: '',
  TemplateDetailSiteRelsChanged: '',
  TemplateDetailRemoveSiteRels: '',
  TemplateDetailSiteRelMoved: '',
  TemplateDetailSave: '',
  TemplateDetailSaveSuccess: '',
  TemplateDetailSaveFailed: '',
  TemplateDetailSiteListSortTypeChanged: '',

  DeliveryCheckTemplateDetailReady: '',
  ApplyDefaultDeliveryCheckTemplateConf: '',
  AddDeliveryCheckTemplateItem: '',
  DeliveryCheckTemplateItemChanged: '',
  RemoveDeliveryCheckTemplateItem: '',
  RemoveDeliveryCheckTemplateItemConfirmed: '',
  RemoveDeliveryCheckTemplateItemCancelled: '',
  DeliveryCheckTemplateItemMoved: '',
  AddDeliveryCheckTemplateItemOption: '',
  DeliveryCheckTemplateItemOptionChanged: '',
  DeliveryCheckTemplateItemOptionDefaultCheckChanged: '',
  DeliveryCheckTemplateItemOptionIsExpectedChanged: '',
  RemoveDeliveryCheckTemplateItemOption: '',
  RemoveDeliveryCheckTemplateItemOptionConfirmed: '',
  RemoveDeliveryCheckTemplateItemOptionCancelled: '',
  DeliveryCheckTemplateDetailSave: '',
  DeliveryCheckTemplateDetailSaveSuccess: '',
  DeliveryCheckTemplateDetailSaveFailed: '',
  ValidateDeliveryCheckTemplateDetail: '',

  ConstructionTemplateConfigSelectSidebarItem: '',
  ConstructionTemplateConfigChanged: '',
  ResetConstructionTemplateConfigChanges: '',

  QuotationTemplateConfigLoaded: '',
  QuotationTemplateConfigChanged: '',
  ResetQuotationTemplateConfigChanges: '',
  QuotationTemplateConfigAddCategory: '',
  QuotationTemplateConfigEditCategory: '',
  QuotationTemplateConfigEditCategoryChanged: '',
  QuotationTemplateConfigEditCategoryCommitted: '',
  QuotationTemplateConfigEditCategoryCancelled: '',
  QuotationTemplateConfigRemoveCategory: '',
  QuotationTemplateConfigCommitRemoveCategory: '',
  QuotationTemplateConfigCancelRemoveCategory: '',
  QuotationTemplateConfigExpandCategory: '',
  QuotationTemplateConfigCollapseCategory: '',
  QuotationTemplateConfigAddGroup: '',
  QuotationTemplateConfigEditGroup: '',
  QuotationTemplateConfigEditGroupChanged: '',
  QuotationTemplateConfigEditGroupCommitted: '',
  QuotationTemplateConfigEditGroupCancelled: '',
  QuotationTemplateConfigRemoveGroup: '',
  QuotationTemplateConfigCommitRemoveGroup: '',
  QuotationTemplateConfigCancelRemoveGroup: '',
  QuotationTemplateConfigCategoryMoved: '',
  QuotationTemplateConfigGroupSelected: '',
  QuotationTemplateConfigAllGroupsSelected: '',
  QuotationTemplateConfigGroupMoved: '',
  QuotationTemplateConfigSave: '',
  QuotationTemplateConfigSaveSuccess: '',
  QuotationTemplateConfigSaveFailed: '',
});
