import { memo } from 'react';
import { Translate } from 'react-localize-redux';
import { useQuotationTemplateConfigContext } from './Context';
import { LinkButton } from './LinkButton';
import { CategoryView } from './category/CategoryView';
import { EmptyCategories } from './category/EmptyCategories';
import { NewCategory } from './category/NewCategory';

export const Sidebar = memo(() => {
  const { state, staged, editable, onAddCategory } =
    useQuotationTemplateConfigContext();

  return (
    <div className={`quotation-tpl__category-panel`}>
      <Header editable={editable} onAddCategory={onAddCategory} />
      <div className={`quotation-tpl__category-panel-body`}>
        {staged.categories.length === 0 &&
          state.categoryNameBeingEdited == null && (
            <EmptyCategories
              editable={editable}
              onAddCategory={onAddCategory}
            />
          )}
        <div className="tpl-detail__category-list">
          {staged.categories.map((category, index) => (
            <CategoryView
              key={category.id}
              category={category}
              index={index}
              canMoveUp={index > 0}
              canMoveDown={index < staged.categories.length - 1}
            />
          ))}
          {state.categoryNameBeingEdited != null &&
            state.categoryIdWhoseNameIsBeingEdited == null && (
              <NewCategory defaultValue={state.categoryNameBeingEdited} />
            )}
        </div>
      </div>
    </div>
  );
});

const Header = memo(
  ({
    editable,
    onAddCategory,
  }: {
    editable: boolean;
    onAddCategory: () => void;
  }) => {
    return (
      <div className={`quotation-tpl__category-panel-hd`}>
        <h3>
          <Translate id="quotation_tpl.category.title" />
          {editable && (
            <LinkButton onClick={onAddCategory}>
              <i className="la la-plus" />
            </LinkButton>
          )}
        </h3>
      </div>
    );
  },
);
