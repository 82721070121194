import { QuotationTemplateGroupStaged } from 'model';
import { memo } from 'react';
import { EmptySubjects } from './EmptySubjects';

export const SubjectList = memo(
  ({
    group,
    editable,
  }: {
    editable: boolean;
    group: QuotationTemplateGroupStaged;
  }) => {
    return (
      <div className="quotation-tpl__subject-panel">
        {group.subjects.length === 0 ? (
          <EmptySubjects editable={editable} />
        ) : null}
      </div>
    );
  },
);
