/**
 * @file: VehicleInspectionTaskQuotationService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleInspectionTaskQuotationServiceBase } from './base/VehicleInspectionTaskQuotationService';

export class VehicleInspectionTaskQuotationService extends VehicleInspectionTaskQuotationServiceBase {
  // add custom service implementation here
}
