import React, { PureComponent } from 'react';
import { ServiceDurationPicker } from 'shared/components/ServiceDurationPicker';
import { FormElementServiceDurationPicker } from '../types';

interface Props<T> {
  element: FormElementServiceDurationPicker<T>;
  disabled?: boolean;
  required?: boolean;
  value?: string;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
}

export class FormServiceDurationPicker<T> extends PureComponent<Props<T>> {
  render() {
    const { disabled, required, value } = this.props;
    return (
      <ServiceDurationPicker
        disabled={disabled}
        required={required}
        value={value}
        placeholder={this.props.element.placeholder}
        onChange={this.onChange}
      />
    );
  }

  onChange = (value: string | null | undefined) => {
    const { element, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const changes: { [K in keyof T]?: T[K] } = {};
    changes[element.prop] = value as any;
    element.onChange && element.onChange(changes as any, extra);
    if (this.props.value === changes[element.prop]) return;
    this.props.onChange(changes as T);
  };
}
