import { memo } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { getString } from 'shared/components';
import { Button } from 'shared/metronic/components';

export const ConfirmModal = memo(
  ({
    title,
    content,
    open = false,
    onConfirm,
    onCancel,
  }: {
    title: string;
    content: string;
    open?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
  }) => {
    return (
      <Modal isOpen={open}>
        <ModalHeader>
          <Translate id={title} />
        </ModalHeader>
        <ModalBody>
          <ReactMarkdown>{getString(content)}</ReactMarkdown>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>
            <Translate id="cancel_btn_text" />
          </Button>
          <Button color="primary" onClick={onConfirm}>
            <Translate id="yes_btn_text" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  },
);
